import React, { Component } from "react";
import { Link } from "react-router-dom";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  CustomInput,
  CardFooter,
  FormGroup,
  Input,
} from "reactstrap";
import NumberFormat from "components/NumberFormat";
import api from "../../api";
import ItemsLoader from "../../components/Loaders/ItemsLoader";
import sendWhatsapp from "../../components/WAMessage";
import ErrorMessage from "../../components/ErrorMessage";

// Redux
import { connect } from 'react-redux';
import mixpanelApi from "../../mixPanel";

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      items: [],
      modalClassic: false,
      limit: (this.props.account.plan.code === '02' && this.props.account.status === 'active' ) ? 25 : 10,
      skip: 0,
      singleSelect: null,
      itemName: '',
      itemSku: '',
      itemIsActive: null,
      alert: null
    };
  }

  componentDidMount() { this.fetchData(this.state.limit, 0) }
  // componentWillUnmount() {  }

  fetchData = async (limit, skip) => {
    try{
      this.setState({ items: [], loading: true, error: null });
      if(skip) this.state.skip += skip;
      if(skip === 0) this.state.skip = 0;
      
      let query = '';
      if(this.state.itemName || this.state.itemSku) query += `&where={`;
      if(this.state.itemName) query += `"name":{"contains":"${this.state.itemName}" }`;
      if(this.state.itemName && this.state.itemSku) query += `,`;
      if(this.state.itemSku) query += `"sku":{"contains":"${this.state.itemSku}" }`;
      if(this.state.itemName || this.state.itemSku) query += `}`;
      if(this.state.itemIsActive) query += `&isActive=${this.state.itemIsActive.value}`

      const products = await api.items.list(limit, this.state.skip, query);

      if(products.ok){
        this.setState({ items: products.body, loading: false });
      }else {
        if( products.body.err && !products.body.err.details ) this.setState({ error: products.body.err, loading: false });
        if( products.body.err && products.body.err.details ) this.setState({ error: products.body.err.details[0].message, loading: false });
        if( products.body.hasOwnProperty("details") ) this.setState({ error: products.body.details, loading: false });
      }
      
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: "Intenta de nuevo" });
    }
  }

  updateItem = async (itemId, isActive) => {
    try{
      const itemUpdated = await api.items.update(itemId, {
        isActive: isActive
      });

      if(itemUpdated.ok){
        let item = this.state.items.find(item => item.id === itemId);
        item.isActive = isActive;
        this.props.notify("tc", "success", `Item ${item.name}, actualizado exitosamente.`);
        this.setState({ items: this.state.items });
      }else {
        if( itemUpdated.body.err && !itemUpdated.body.err.details ) this.props.notify("tc", "danger", `Error al actualizar producto: ${itemUpdated.body.err}.`, 10);
        if( itemUpdated.body.err && itemUpdated.body.err.details ) this.props.notify("tc", "danger", `Error al actualizar producto: ${itemUpdated.body.err.details[0].message}.`, 10);
        if( itemUpdated.body.hasOwnProperty("details") ) this.props.notify("tc", "danger", `Error al actualizar producto: ${itemUpdated.body.details}.`, 10);
      }
    } catch (error) {
      console.log(error);
      this.props.notify("tc", "danger", `Error al actualizar producto, intenta de nuevo.`, 10);
    }
  }

  invalidAccount = () => {
    if( 
      ( (this.state.items.length < this.props.account.plan.maxItems) && (this.props.account.plan.code === '01') ) ||
      ( (this.props.account.plan.code === '02') && (this.props.account.status === 'active') )
    ) {
      this.props.history.push('/item/create');
    }else if ( (this.state.items.length >= this.props.account.plan.maxItems) && (this.props.account.plan.code === '01') ){
      return this.warningWithConfirmMessage("¿Te faltan productos?", "No hay problema, Expoceramica Pro es sin límites.", true);
    }else if ( (this.props.account.plan.code === '02') && (this.props.account.status === 'inactive') ){
      return this.warningWithConfirmMessage("¡Tu suscripción ha vencido!", "Contáctanos para renovar tu suscripción.", false);
    }
  }

  warningWithConfirmMessage = (title, msg, freemium) => {
    this.setState({
      alert: 
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          mixpanelApi.general.upgradePlan(this.props.account.plan.name);
          sendWhatsapp(freemium, this.props.account.name)}
        }
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText={freemium ? 'Pasarme a Pro' : 'Actualizar Plan'}
        cancelBtnText="Cancelar"
        showCancel
        btnSize=""
        cancelBtnCssClass="text-white"
        confirmBtnCssClass="text-white"
        focusConfirmBtn={false}
      >
        {msg}
      </ReactBSAlert>
    });
  }

  hideAlert = () => {
    this.setState({ alert: null});
  };

  render() {
    if (this.state.loading === true) {
      return (
        <ItemsLoader />
      );
    }else if (this.state.error !== null) {
      return (
        <ErrorMessage message={this.state.error} />
      );
    }else{
      return (
        <>
          {this.state.alert}
          <Row>
            <Col md="12" className="text-right pb-2">
              <Button
                color="primary"
                onClick={() => this.invalidAccount()}
              >
                Nuevo
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <Input
                          type="text"
                          value={this.state.itemName}
                          onChange={(e) => {
                            this.setState({ itemName: e.target.value ? e.target.value : '' });
                          }}
                          placeholder={`Por nombre...`}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Input
                          type="text"
                          value={this.state.itemSku}
                          onChange={(e) => {
                            this.setState({ itemSku: e.target.value ? e.target.value : '' });
                          }}
                          placeholder={`Por código/sku...`}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="status"
                        value={this.state.itemIsActive}
                        onChange={(value) => {
                          this.setState({ itemIsActive: value });
                        }}
                        options={[
                          { value: true, label: "Activo" },
                          { value: false, label: "Inactivo" }
                        ]}
                        placeholder="Estado..."
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-simple py-1"
                      onClick={() => this.fetchData(this.state.limit, 0)}
                      color="primary">
                      <i className="fas fa-filter"></i>{" "}
                      Filtrar busqueda
                    </Button>
                    <Button
                      className="btn-icon btn-simple"
                      onClick={(e) => {
                        this.setState({
                          itemName: '',
                          itemSku: '',
                          itemIsActive: null
                        }, () => {
                          this.fetchData(this.state.limit, 0);
                        });
                      }}
                      color="neutral">
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table responsive className="table-shopping table table-hover mb-0">
                    <thead className="text-primary">
                      <tr>
                        <th></th>
                        <th>Nombre</th>
                        <th>Código/SKU</th>
                        <th>Precio</th>
                        <th className="text-center">Estado</th>
                        {/* <th className="text-right">Acciones</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map( item => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <div className="img-container">
                                <Link to={`/item/edit/${item.id}`}>
                                  {( item.defaultImage !== null )
                                    ? <img src={item.defaultImage} alt={item.itemName} />
                                    : <img src={require("assets/img/item-placeholder.png").default} alt="cloudbizShop-placeholder" />
                                  }
                                </Link>
                              </div>
                            </td>
                            <td><Link to={`/item/edit/${item.id}`}>{item.name}</Link></td>
                            <td><Link to={`/item/edit/${item.id}`}>{item.sku}</Link></td>
                            <td>
                              <NumberFormat
                                typeText={'span'}
                                number={item.price}
                                prefix={`${this.props.account.currency.symbol}`}
                              />
                            </td>
                            <td className="text-right">
                              <div className="d-flex align-items-center justify-content-center">
                                {/* <span className="mr-2">Inactivo</span> */}
                                <CustomInput
                                  type="switch"
                                  id={'switch-'+item.id}
                                  className="mt-n4"
                                  onChange={(e) => this.updateItem(item.id, e.target.checked)}
                                  defaultChecked={item.isActive}
                                />
                                <span className="ml-n2">{ item.isActive ? 'Activo' : 'Inactivo' }</span>
                              </div>
                            </td>
                            {/* <td className="text-right">
                              <Button
                                className="btn-link"
                                color="danger"
                                id="tooltip974171201"
                                size="sm"
                                onClick={() => this.deteItem(item.id)}
                              >
                                <i className="tim-icons icon-simple-remove" />
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip974171201"
                              >
                                Eliminar item
                              </UncontrolledTooltip>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  { (this.props.account.plan.code === '02' && this.props.account.status === 'active') &&
                    <div className="float-right">
                      { (this.state.skip !== 0) &&
                        <Button
                          onClick={() => this.fetchData(this.state.limit, (this.state.limit * -1))}
                          className="btn-simple"
                          color="primary"
                        >
                          <i className="tim-icons icon-minimal-left"></i>
                        </Button>
                      }
                      { (this.state.items.length === this.state.limit) &&
                        <Button 
                          onClick={() => this.fetchData(this.state.limit, this.state.limit)}
                          className="btn-simple"
                          color="primary"
                        >
                          <i className="tim-icons icon-minimal-right"></i>
                        </Button>
                      }
                    </div>
                  }
                  { (this.props.account.plan.code === '02' && this.props.account.status === 'active') &&
                    <Col lg="3" md="6" sm="12" style={{display: 'flex', alignItems: 'center'}}>
                      mostrar
                      <Select
                        className="react-select info my-1 mx-1 w-100"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={{ value: this.state.limit, label: this.state.limit }}
                        onChange={(element) => {
                          this.setState({limit: element.value});
                          this.fetchData(element.value, 0);
                        }}
                        options={[
                          { value: 25, label: "25" },
                          { value: 100, label: "100" },
                          { value: 250, label: "250" },
                        ]}
                        placeholder="25"
                      />
                      registros
                    </Col>
                  }
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
};

export default connect(mapStateToProps)(Items);
