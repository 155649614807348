import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
// import RTLLayout from "layouts/RTL/RTL.js";
import { ProtectedRoute } from './protected.route';
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
if(process.env.NODE_ENV  === 'production') hotjar.initialize(2484887, 6);

function App(){
    return (
        <Provider store={store} >
            <PersistGate loading={null} persistor={persistor} >
                <BrowserRouter>
                    <Switch>
                        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                        <ProtectedRoute path="/" component={AdminLayout} />
                        <Redirect from="*" to="/dashboard" />
                    </Switch>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;