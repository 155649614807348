import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import {
  Row,
  // Spinner,
  Card,
  CardBody,
  Col
} from 'reactstrap';


export default class CategoriesLoading extends Component {
  render() {
    return (
      // <div className="loader-container">
      //   <Spinner animation="border" />
      // </div>
      <Row>
        <Col lg="3" md="6">
          <Card className="card-stats" style={{minHeight: '234px'}}>
            <CardBody>
              <ContentLoader width={290} height={200}>
                <circle cx="150" cy="50" r="30" />
                <rect x="70" y="110" rx="4" ry="4" width="170" height="10" />
                <rect x="20" y="130" rx="4" ry="4" width="260" height="7" />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" md="6">
          <Card className="card-stats" style={{minHeight: '234px'}}>
            <CardBody>
              <ContentLoader width={290} height={200}>
                <circle cx="150" cy="50" r="30" />
                <rect x="70" y="110" rx="4" ry="4" width="170" height="10" />
                <rect x="20" y="130" rx="4" ry="4" width="260" height="7" />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
