import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Col,
  FormGroup,
} from "reactstrap";
import PhoneInput from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es';
import 'react-phone-number-input/style.css';
import 'assets/css/signup.css';

import api from '../../api';

// Redux
import { connect } from 'react-redux';
import mixpanelApi from "../../mixPanel";

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

const Login = (props) => {
  const [state, setState] = React.useState({});
  const [mobileNumber, setmobileNumber] = React.useState();
  const [password, setpassword] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [mobileNumberState, setmobileNumberState] = React.useState("");
  const [passwordState, setpasswordState] = React.useState("");
  const [error, setError] = React.useState(null);
  const stateFunctions = {
    setmobileNumber: (value) => setmobileNumber(value),
    setmobileNumberState: (value) => setmobileNumberState(value),
    setpassword: (value) => setpassword(value),
    setpasswordState: (value) => setpasswordState(value),
  }

  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
    return true;
    }
    return false;
  };

  // function that verifies if value contains only numbers
  const verifyNumber = (value) => {
    if(value !== undefined)
      value = value.split('+');

    var numberRex = new RegExp("^[0-9.]+$");
    if (numberRex.test(value !== undefined ? value[1] : value)) {
      return true;
    }
    return false;
  };

  const change = (value, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (verifyLength(value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "number":
        if (verifyNumber(value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      default:
        break;
    }

    stateFunctions["set" + stateName](value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);
    if (mobileNumberState === "") {
      setmobileNumberState("has-danger");
    }if (passwordState === ""){
      setpasswordState("has-danger");
    }else if(mobileNumberState === "has-success" && passwordState === "has-success") {
      setLoading(true);
    
      try{
        let whatsapp = null;
        if(mobileNumber) whatsapp = mobileNumber.split('+');
  
        let body = {
          mobileNumber: whatsapp[1],
          password: password
        };

        const response = await api.auth.login(body);
        
        if(response.ok){
          localStorage.setItem('token', response.body.token);
          props.dispatch({
            type: 'SET_ACCOUNT',
            payload: { account: response.body.user.account }
          });

          props.dispatch({
            type: 'SET_USER',
            payload: { user: response.body.user }
          });
          mixpanelApi.auth.login(response.body.user);
          setLoading(false);
          props.history.push('/dashboard');
        }else {
          if(response.body.err === "Unauthorized" && !response.body.err.details) setError("Usuario o contraseña incorrecto");
          else if(response.body.err === "User not found" && !response.body.err.details) setError("Whatsapp ingresado no esta registrado");
          else if(response.body.err && !response.body.err.details ) setError(response.body.err);
          else if(response.body.err.details[0].context.key === "mobileNumber") setError("WhatsApp es requerido");
          else if(response.body.err.details[0].context.key === "password") setError("Contraseña es requerido");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="6" md="6">
            <Form className="form" onSubmit={handleSubmit}>
              <div className="text-center pb-5">
                <img
                  alt="expoceramica-logo"
                  src={require("assets/img/expoceramica-logo.png").default}
                  width="200"
                />
              </div>

              <Card className="card-login card-white px-5" style={{boxShadow: '0 1px 10px 0px rgba(0, 0, 0, 0.3)'}}>
                <CardHeader className="text-center">
                  <CardTitle tag="h1" className="mb-3">Bienvenido</CardTitle>
                  <p style={{fontSize: '1rem'}}>¿No tienes una cuenta? {" "}
                    <Link to="/auth/signup">
                      Crea tu cuenta
                    </Link>
                  </p>
                </CardHeader>
                <CardBody>
                  <p>Whatsapp</p>
                  <FormGroup
                    className={classnames({
                      "input-group-focus": state.emailFocus,
                    },
                    "mb-4"
                    )
                    }
                  >
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="HN"
                      value={mobileNumber}
                      onChange={(phone) => change(phone, "mobileNumber", "number", 1)}
                      placeholder={'+504 0000 0000'}
                      labels={es}
                      countryOptionsOrder={['HN', 'GT', 'SV']}
                    />
                    {mobileNumberState === "has-danger" ? (
                      <label className="error text-danger">Whatsapp es requerido.</label>
                    ) : null}
                  </FormGroup>

                  <p>Contraseña</p>
                  <FormGroup
                    className={classnames({
                      "input-group-focus": state.passFocus,
                    })}
                  >
                    <Input
                      placeholder="Contraseña"
                      type="password"
                      onFocus={(e) => setState({ ...state, passFocus: true })}
                      onBlur={(e) => setState({ ...state, passFocus: false })}
                      // onChange={e => setPassword(e.target.value)}
                      onChange={(e) => change(e.target.value, "password", "length", 1)}
                    />
                    {passwordState === "has-danger" ? (
                      <label className="error text-danger">Contraseña es requerido.</label>
                    ) : null}
                  </FormGroup>
                  {error &&
                    <p className="text-danger text-center">{error}</p>
                  }
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    disabled={loading}
                    type="submit"
                    color="primary"
                    size="lg"
                  >
                    { loading ? 'Iniciando sesión...' : 'Iniciar sesión' }
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Login);
