import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import logoDefault from "../../assets/img/logo-default.png";
import flagHN from "../../assets/img/honduras-flag.png";
import flagSV from "../../assets/img/el-salvador-flag.png";
import flagGT from "../../assets/img/guatemala-flag.png";
import flagINT from "../../assets/img/international-flag.jpg";

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({
      type: 'LOGOUT'
    })
  }
}

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };

  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText ? props.brandText : ''}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavbarBrand className="m-auto">
                { props.account.name ? props.account.name : 'Nombre de Negocio' }
              </NavbarBrand>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img
                      alt={ props.account.name }
                      src={props.account.logo ? props.account.logo : logoDefault}
                    />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item p-0">
                      <div className="photo mr-1" style={{width: '30px', height: '30px'}}>
                        <img
                          alt={ props.account.name }
                          src={ props.account.country === '60884f467b39de27e5a0c6e3' ? flagHN :
                                props.account.country === '60884f5a7b39de27e5a0c6e4' ? flagSV :
                                props.account.country === '60884f617b39de27e5a0c6e5' ? flagGT :
                                flagINT
                              }
                        />
                      </div>
                      { props.account.country === '60884f467b39de27e5a0c6e3' ? 'Honduras' :
                        props.account.country === '60884f5a7b39de27e5a0c6e4' ? 'El Salvador' :
                        props.account.country === '60884f617b39de27e5a0c6e5' ? 'Guatemala' :
                        'Internacional'
                      }
                    </DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => props.history.push(`/user-profile`)}>Configuración</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => props.history.push(`/payment-methods`)}>Métodos de pago</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => {
                      localStorage.removeItem('token');
                      props.logout();
                      props.history.push(`/auth/login`);
                    }}>Cerrar sesión</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
