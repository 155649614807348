const initState = {
    // account: {},
    itemImages: []
}

function data(state = initState, action) {
    switch (action.type) {
        case 'SET_ACCOUNT': {
            return {...state, account: action.payload.account }
        }
        case 'UPDATE_ACCOUNT': {
            return {...state, account: action.account};
        }
        case 'SET_USER': {
            return {...state, user: action.payload.user }
        }
        case 'UPDATE_USER': {
            return {...state, user: action.user};
        }
        case 'SET_ITEM_IMAGE': {
            return {...state, itemImages: action.itemImages }
        }
        case 'ADD_ITEM_IMAGE' : {
            return {...state, itemImages: [action.newItem, ...state.itemImages]}
        }
        case 'UPDATE_ITEM_IMAGE': {
            return {...state, 
                itemImages: state.itemImages.map(
                    (content, i) => content.base64 === action.image.base64 ? {
                        ...content,
                        base64: action.image.base64,
                        isDefault: action.image.isDefault } : content
                )
            }
        }
        case 'REMOVE_ITEM_IMAGE' : {
            return {...state, itemImages: state.itemImages.filter(item => item.base64 !== action.image.base64)}
        }
        case 'EMPTY_ITEM_IMAGE' : {
            return{...state, itemImages: []}
        }
        case 'LOGOUT' : {
            return state = initState
        }
        default:
            return state
    }
}

export default data;