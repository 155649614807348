import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import {
  Row,
  // Spinner,
  Card,
  CardBody,
  Col
} from 'reactstrap';


export default class OrderLoading extends Component {
  render() {
    return (
      <Row>
        <Col lg="5">
          <Card className="card-stats mb-3">
            <CardBody>
              <ContentLoader
                height={84}
                width={523}
              >
                <rect x="385" y="12" rx="3" ry="3" width="123" height="7" />
                <rect x="340" y="40" rx="5" ry="5" width="171" height="10" />
                <circle cx="44" cy="42" r="38" />
              </ContentLoader>
            </CardBody>
          </Card>

          <Card className="card-stats mb-3">
            <CardBody>
              <ContentLoader
                height={84}
                width={523}
              >
                <rect x="385" y="12" rx="3" ry="3" width="123" height="7" />
                <rect x="340" y="40" rx="5" ry="5" width="171" height="10" />
                <circle cx="44" cy="42" r="38" />
              </ContentLoader>
            </CardBody>
          </Card>

          <Card className="card-stats mb-3">
            <CardBody>
              <ContentLoader
                height={84}
                width={523}
              >
                <rect x="385" y="12" rx="3" ry="3" width="123" height="7" />
                <rect x="340" y="40" rx="5" ry="5" width="171" height="10" />
                <circle cx="44" cy="42" r="38" />
              </ContentLoader>
            </CardBody>
          </Card>

          <Card className="card-stats mb-3">
            <CardBody>
              <ContentLoader
                height={84}
                width={523}
              >
                <rect x="385" y="12" rx="3" ry="3" width="123" height="7" />
                <rect x="340" y="40" rx="5" ry="5" width="171" height="10" />
                <circle cx="44" cy="42" r="38" />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          <Card className="card-stats" style={{height: '700px'}}>
            <CardBody>
              <ContentLoader
                width={756}
                height={546}
                viewBox="0 0 756 546"
              >
                <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="23" rx="5" ry="5" width="253" height="30" />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
