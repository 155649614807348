import React from 'react';
import NumberFormat from './NumberFormat';

const OrderPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="mx-4">
      <h3 style={{color: 'black'}}>Pedido No. {props.order.code}</h3>
      <div>
        <div style={{float: 'left', width: '50%', paddingRight: 10}}>
          <p style={{color: 'black'}}>Fecha: {props.formatDate(props.order.createdAt)}</p>
          <p style={{color: 'black'}}>Estado: {' '}
            {
              props.order.status === 'pending' ? 'Pendiente' : 
              props.order.status === 'accepted' ? 'Aceptado' : 
              props.order.status === 'delivering' ? 'Entregando' :
              props.order.status === 'completed' ? 'Completado' :
              'Cancelado'
            }
          </p>
          <p style={{color: 'black'}}>Pago: {props.order.paymentStatus === 'pending' ? 'No Pagado' : 'Pagado'}</p>
        </div>
        <div style={{float: 'left', width: '50%', paddingLeft: 10}}>
          <p style={{color: 'black'}}>Cliente: {props.order.customerName}</p>
          <p style={{color: 'black'}}>Whatsapp: +{props.order.customerPhone}</p>
          <p style={{color: 'black'}}>Dirección: {props.order.shippingAddress}</p>
        </div>
      </div>
      <div>
        <p style={{color: 'black'}}>Comentarios: {props.order.notes}</p>
      </div>

      <table style={{width: '100%', marginTop: '5%'}}>
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {props.order.items.map( item => {
            return (
              <tr key={item.id}>
                <td>
                  <div>
                    {( item.defaultImage )
                      ? <img src={item.defaultImage} alt={item.itemName} width='90' height='90' />
                      : <img src={require("assets/img/item-placeholder.png").default} alt="expoceramica-placeholder" width='90' height='90' />
                    }
                  </div>
                </td>
                <td>
                  {item.itemSku ? `${item.itemSku} -` : ''} {item.itemName}
                  {item.variants &&
                    item.variants.map( variant => {
                      let [value] = Object.entries(variant);
                      return (
                        <small key={value[0]}>
                          <br/>
                          {value[0]}: {value[1][0]}
                        </small>
                      );
                    })
                  }
                </td>
                <td>
                  <p>
                    <NumberFormat
                      typeText={'span'}
                      className={'mr-1'}
                      number={item.price}
                      prefix={`${props.currency.symbol}`}
                    />
                    ({item.quantity})
                  </p>
                </td>
                <td>
                  <NumberFormat
                    typeText={'span'}
                    number={item.total}
                    prefix={`${props.currency.symbol}`}
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <th></th>
            <th></th>
            <th>Total</th>
            <th>
              <NumberFormat
                typeText={'span'}
                number={props.order.total}
                prefix={`${props.currency.symbol}`}
              />
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default OrderPrint;
