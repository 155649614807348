import React from 'react';

import { Col } from 'reactstrap';

function ErrorMessage (props) {
    return (
        <Col md="12" className="py-5 text-center">
            <h1><i className="fas fa-exclamation-triangle"></i></h1>
            <h3>!Oops, algo salió mal!</h3>
            <p>{props.message}</p>
        </Col>
    );
}

export default ErrorMessage;