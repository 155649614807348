import mixpanel from 'mixpanel-browser';

const mixpanelApi = {
    auth: {
        // Esta funcion se ejecuta despues que el usuario se registra exitosamente
        accountCreated(account) {
            mixpanel.alias(account.user.mobileNumber);
            mixpanel.register({
                'Plan Type': 'FREEMIUM',
            });
            mixpanel.track('Sign Up');
            mixpanel.people.set({
                'Plan Type': 'FREEMIUM',
                $name: account.user.account.name,
                $number: account.user.account.mobileNumber,
                $country: account.countryName,
            });
        },
        // Esta funcion se ejecuta despues que el usuario inicia sesion exitosamente
        login(user) {
            mixpanel.identify(user.mobileNumber);
            mixpanel.register({
              'Plan Type': user.account.plan.name,
            });
            mixpanel.people.set({
              'Plan Type': user.account.plan.name,
              $name: user.fullName,
              $number: user.mobileNumber,
              'Account Name': user.account.name,
            });
        },
        // Esta funcion se ejecuta cuando el usuario abre la pagina de CREAR CUENTA
        viewSignupPage() {
            mixpanel.track('View Sign Up Page');
        }
    },
    items: {
        // Esta funcion se ejecuta despues que el usuario crea un item exitosamente
        itemCreated(item) {
            mixpanel.track('Create Item', {
                Item: item.id,
            });
            mixpanel.people.set({
                'Last Item Created': new Date().toISOString(),
            });
            mixpanel.people.set_once({
                'First Item Created': new Date().toISOString(),
            });
        }
    },
    categories: {
        // Esta funcion se ejecuta despues que el usuario crea una categoria exitosamente
        categoryCreated(category) {
            mixpanel.track('Create Category', {
                Category: category.id,
            });
            mixpanel.people.set({
                'Last Category Created': new Date().toISOString(),
            });
            mixpanel.people.set_once({
                'First Category Created': new Date().toISOString(),
            });
        }
    },
    general: {
        upgradePlan(plan) {
            mixpanel.track('Upgrade Plan', {
              'Plan Type': plan,
            });
        }
    }
}

export default mixpanelApi;