const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

// function getRadianAngle(degreeValue) {
//   return (degreeValue * Math.PI) / 180
// }

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  // const maxSize = Math.max(image.width, image.height)
  let safeArea = 0;
  if( image.width >= 1500 && image.height >= 1500 )
    safeArea = 1000
  else
    safeArea = pixelCrop.width

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // Set white background color to cropped area
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  // draw rotated image and store data.
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      canvas.width,
      canvas.height
    )

  // As Base64 string
  return canvas.toDataURL('image/jpeg');

  // As a blob
//   return new Promise(resolve => {
//     canvas.toBlob(file => {
//       resolve(URL.createObjectURL(file))
//     }, 'image/jpeg')
//   })
}
