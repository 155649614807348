import React, { useEffect } from "react";
import { useParams } from "react-router";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";
import api from "../../api";
import mixpanelApi from "../../mixPanel";
import ErrorMessage from "../../components/ErrorMessage";

const CategoryForm = (props) => {
    const [categoryName, setcategoryName] = React.useState("");
    const [categoryNameState, setcategoryNameState] = React.useState("");
    const [categoryDescription, setcategoryDescription] = React.useState("");
    const [categoryDescriptionState, setcategoryDescriptionState] = React.useState("");
    const stateFunctions = {
        setcategoryName: (value) => setcategoryName(value),
        setcategoryNameState: (value) => setcategoryNameState(value),
        setcategoryDescription: (value) => setcategoryDescription(value),
        setcategoryDescriptionState: (value) => setcategoryDescriptionState(value),
    }
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [isUpdate, setisUpdate] = React.useState(false);
    const { categoryId } = useParams();

    useEffect(() => {
        async function fetchData() {
            try {
                setError(null);
                const categoryInfo = await api.categories.show(categoryId);

                if(categoryInfo.ok){
                    setcategoryName(categoryInfo.body.name);
                    setcategoryDescription(categoryInfo.body.description);
                    if(categoryInfo.body.name) setcategoryNameState("has-success");
                    if(categoryInfo.body.description) setcategoryDescriptionState("has-success");
                }else{
                    if( categoryInfo.body.err && !categoryInfo.body.err.details ) setError(categoryInfo.body.err);
                    if( categoryInfo.body.err && categoryInfo.body.err.details ) setError(categoryInfo.body.err.details[0].message);
                    if( categoryInfo.body.hasOwnProperty("details") ) setError(categoryInfo.body.details);
                }
            } catch (error) {
                console.log(error);
                setError("Intenta de nuevo");
            }
        }

        if(categoryId){
            setisUpdate(true);
            fetchData();
        }
    }, [ categoryId ]);

    // function that verifies if a string has a given length or not
    const verifyLength = (value, length) => {
        if (value.length >= length) {
        return true;
        }
        return false;
    };

    const change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        switch (type) {
          case "length":
            if (verifyLength(event.target.value, stateNameEqualTo)) {
              stateFunctions["set" + stateName + "State"]("has-success");
            } else {
              stateFunctions["set" + stateName + "State"]("has-danger");
            }
            break;
          default:
            break;
        }

        stateFunctions["set" + stateName](event.target.value);
    };

    const createCategory = async () => {
        if (categoryNameState === "") {
            setcategoryNameState("has-danger");
        }else {
            setLoading(true);
            try{ 
                const category = await api.categories.create({
                    name: categoryName,
                    description: categoryDescription ? categoryDescription : null
                });

                if(category.ok){
                    mixpanelApi.categories.categoryCreated(category);
                    setLoading(false);
                    props.notify("tc", "success", 'Categoría creada exitosamente.');
                    props.history.push('/categories');
                }else {
                    if( category.body.err && !category.body.err.details ) props.notify("tc", "danger", `Error al guardar categoría: ${category.body.err}.`, 10);
                    if( category.body.err && category.body.err.details ) props.notify("tc", "danger", `Error al guardar categoría: ${category.body.err.details[0].message}.`, 10);
                    if( category.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al guardar categoría: ${category.body.details}.`, 10);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
                props.notify("tc", "danger", `Error al guardar categoría, intenta de nuevo.`, 10);
            }
        }
    };

    const updateCategory = async () => {
        if (categoryNameState === "") {
            setcategoryNameState("has-danger");
        }else {
            setLoading(true);
            try{ 
                const category = await api.categories.update(
                categoryId,
                {
                    name: categoryName,
                    description: categoryDescription ? categoryDescription : null
                });

                if(category.ok){
                    props.notify("tc", "success", `Categoría ${category.body.name}, actualizado exitosamente.`);
                    props.history.push('/categories');
                }else {
                    if( category.body.err && !category.body.err.details ) props.notify("tc", "danger", `Error al actualizar categoría: ${category.body.err}.`, 10);
                    if( category.body.err && category.body.err.details ) props.notify("tc", "danger", `Error al actualizar categoría: ${category.body.err.details[0].message}.`, 10);
                    if( category.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al actualizar categoría: ${category.body.details}.`, 10);
                }

                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
                props.notify("tc", "danger", `Error al actualizar categoría, intenta de nuevo.`, 10);
            }
        }
    };

    if(error !== null){
        return (
            <ErrorMessage message={error} />
        )
    }else{
        return (
            <>
                <Row>
                    <Col md="12" className="text-right pb-2">
                        <Button
                            onClick={() => props.history.push('/categories')}
                            disabled={loading}
                            color="neutral"
                            className="btn-simple"
                        >
                            Atrás
                        </Button>
                        <Button
                            disabled={loading}
                            color="primary"
                            onClick={isUpdate ? updateCategory : createCategory}
                        >
                            Guardar
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm="10" md="7" className="ml-auto mr-auto">
                        <Form id="LoginValidation">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{ categoryId ? categoryName : 'Nueva Categoría' }</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className={`has-label ${categoryNameState}`}>
                                    <label>Nombre *</label>
                                    <Input
                                        name="fullname"
                                        type="text"
                                        onChange={(e) => change(e, "categoryName", "length", 1)}
                                        defaultValue={categoryName}
                                    />
                                    {categoryNameState === "has-danger" ? (
                                    <label className="error">Nombre es requerido.</label>
                                    ) : null}
                                </FormGroup>
                                <FormGroup className={`has-label ${categoryDescriptionState}`}>
                                    <label>Descripción</label>
                                    <Input
                                        cols="80"
                                        rows="4"
                                        name="description"
                                        type="textarea"
                                        onChange={(e) => change(e, "categoryDescription", "length", 1)}
                                        defaultValue={categoryDescription}
                                    />
                                </FormGroup>
                                <span className="text-danger">{error}</span>
                                </CardBody>
                                {/* <CardFooter className="text-left">
                                    <Button color="success" onClick={loginClick}>
                                        Login
                                    </Button>
                                </CardFooter> */}
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }
};

export default CategoryForm;