/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import NumberFormat from "../components/NumberFormat";
import DasboardLoader from "../components/Loaders/DasboardLoader";
import ErrorMessage from "../components/ErrorMessage";

import api from '../api';

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

const Dashboard = (props) => {
  let date = new Date();
  const [loadingOrderTotal, setloadingOrderTotal] = React.useState(true);
  const [loadingOrderCount, setloadingOrderCount] = React.useState(true);
  const [dateFrom, setDateFrom] = React.useState(new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0));
  const [dateTo, setDateTo] = React.useState(new Date(date.getFullYear(), date.getMonth()+1, 0, 23, 59, 59));
  const [orderTotalAmount, setorderTotalAmout] = React.useState(0);
  const [orderTotal, setorderTotal] = React.useState(0);
  const [orderPending, setorderPending] = React.useState(0);
  const [orderAccepted, setorderAccepted] = React.useState(0);
  const [orderDelivery, setorderDelivery] = React.useState(0);
  const [orderCompleted, setorderCompleted] = React.useState(0);
  const [orderCanceled, setorderCanceled] = React.useState(0);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setError(null);
    let date = new Date();
    orderTotalFetch(new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth()+1, 0, 23, 59, 59));
    orderCountFetch(new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth()+1, 0, 23, 59, 59));
  }, []);

  const orderTotalFetch = async (dateFrom, dateTo) => {
    try{
      setloadingOrderTotal(true);
      const orderTotal = await api.report.orderTotal(dateFrom.getTime(), dateTo.getTime());
      if(orderTotal.ok){
        setloadingOrderTotal(false);
        setorderTotalAmout(parseFloat(orderTotal.body));
      }else {
        if( orderTotal.body.err && !orderTotal.body.err.details ) setError(orderTotal.body.err);
        if( orderTotal.body.err && orderTotal.body.err.details ) setError(orderTotal.body.err.details[0].message);
        setloadingOrderTotal(false);
      }
    } catch (error) {
      console.log(error);
      setError("Intenta de nuevo");
      setloadingOrderTotal(false);
    }
  }

  const orderCountFetch = async (dateFrom, dateTo) =>  {
    try{
      setloadingOrderCount(true);
      const orderCount = await api.report.orderCount(dateFrom.getTime(), dateTo.getTime());
      if(orderCount.ok){
        setloadingOrderCount(false);
        setorderTotal(orderCount.body.Total);
        setorderPending(orderCount.body.Pending);
        setorderAccepted(orderCount.body.Accepted);
        setorderDelivery(orderCount.body.Delivery);
        setorderCompleted(orderCount.body.Completed);
        setorderCanceled(orderCount.body.Canceled);
      }else {
        if( orderCount.body.err && !orderCount.body.err.details ) setError(orderCount.body.err);
        if( orderCount.body.err && orderCount.body.err.details ) setError(orderCount.body.err.details[0].message);
        setloadingOrderCount(false);
      }
    } catch (error) {
      console.log(error);
      setError("Intenta de nuevo");
      setloadingOrderCount(false);
    }
  }

  if(loadingOrderTotal || loadingOrderCount){
    return (
      <DasboardLoader />
    )
  }else if(error !== null){
    return (
      <ErrorMessage message={error} />
    )
  }else{
    return (
      <>
        <Row>
          <Col lg="12" className="row justify-content-end">
            <FormGroup>
              <small>Fecha desde</small>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Fecha desde",
                }}
                timeFormat={false}
                dateFormat="Y-MM-DD"
                onChange={(e) => {
                  if(e._d){
                    e._d.setHours(0, 0, 0);
                    setDateFrom(e._d)
                  }
                }}
                value={dateFrom}
                className="mx-1"
              />
            </FormGroup>
            <FormGroup>
              <small>Fecha hasta</small>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Fecha hasta",
                }}
                timeFormat={false}
                dateFormat="Y-MM-DD"
                onChange={(e) => {
                  if(e._d){
                    e._d.setHours(23, 59, 59);
                    setDateTo(e._d)
                  }
                }}
                value={dateTo}
                className="mx-1"
              />
            </FormGroup>
            <Button
              disabled={loadingOrderTotal || loadingOrderCount}
              color="neutral"
              className="btn-sm"
              onClick={() => {
                setError(null);
                orderTotalFetch(dateFrom, dateTo);
                orderCountFetch(dateFrom, dateTo);
              }}
              style={{maxHeight: '38px',  marginTop: '1.5%'}}
            >
              {(loadingOrderTotal || loadingOrderCount) ? 'Generando...' : 'Generar'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-grey">
                      <i className="fas fa-dollar-sign" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                    <CardTitle tag="h4" className="mb-0">Valor de pedidos</CardTitle>
                      <CardTitle tag="h3" className="font-weight-600">
                        <NumberFormat
                          typeText={'span'}
                          number={orderTotalAmount}
                          prefix={`${props.account.currency.symbol}`}
                        />
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="tim-icons icon-notes" /> Total del valor de pedidos generados
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="6" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-grey">
                      <i className="fas fa-cart-arrow-down" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <CardTitle tag="h4" className="mb-0">Cantidad de pedidos</CardTitle>
                      <CardTitle tag="h3" className="font-weight-600">{orderTotal}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="tim-icons icon-notes" /> Total de la cantidad de pedidos generados
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-warning">
                      <i className="fa fa-exclamation" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Pedidos<br/>Pendientes</p>
                      <CardTitle tag="h3">{orderPending}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="tim-icons icon-notes" /> Pendiente de tu aprobación
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-info">
                      <i className="fas fa-thumbs-up" style={{paddingTop: '0.7rem'}}></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Pedidos<br/>Aceptados</p>
                      <CardTitle tag="h3">{orderAccepted}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="tim-icons icon-notes" /> Confirmados por ti
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-secondary">
                      <i className="fa fa-motorcycle px-0" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Pedidos<br/>Entregando</p>
                      <CardTitle tag="h3">{orderDelivery}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="tim-icons icon-notes" /> Estan siendo entregados al ciente
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-success">
                      <i className="fa fa-check" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Pedidos<br/>Completados</p>
                      <CardTitle tag="h3">{orderCompleted}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="tim-icons icon-notes" /> Recibido por el cliente
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-danger">
                      <i className="fa fa-times" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Pedidos<br/>Cancelados</p>
                      <CardTitle tag="h3">{orderCanceled}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="tim-icons icon-notes" /> Cancelados por ti
                </div>
              </CardFooter> */}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
};

export default connect(mapStateToProps)(Dashboard);