import React from "react";
import { useParams } from "react-router";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import chroma from 'chroma-js';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import NumberFormat from "../components/NumberFormat";
import OrderLoading from "../components/Loaders/OrderLoader";
import ErrorMessage from "../components/ErrorMessage";
import OrderPrint from "../components/OrderPrint";
import ReactToPrint from "react-to-print";

import api from "../api";

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    account: state.account
  }
}


const OrderDetail = (props) => {
  const { orderId } = useParams();
  const [items, setItems] = React.useState([]);
  const [order, setOrder] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [paymentStatus, setpaymentStatus] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [printIcon, setprintIcon] = React.useState('print');
  var componentRef = React.useRef(null);

  React.useEffect(() => {
    async function fetchOrder() {
      try{
        setError(null);
        setLoading(true);
        const orderInfo = await api.orders.show(orderId);
        if(orderInfo.ok){
          setOrder(orderInfo.body);
          setItems(orderInfo.body.items);
          setStatus({
            value: orderInfo.body.status,
            label: orderInfo.body.status === 'pending' ? 'Pendiente' : 
              orderInfo.body.status === 'accepted' ? 'Aceptado' : 
              orderInfo.body.status === 'delivering' ? 'Entregando' :
              orderInfo.body.status === 'completed' ? 'Completado' :
              'Cancelado',
            color: orderInfo.body.status === 'pending' ? '#FFBD59' : 
              orderInfo.body.status === 'accepted' ? '#0A91DF' : 
              orderInfo.body.status === 'delivering' ? '#CB6CE6' :
              orderInfo.body.status === 'completed' ? '#5ACF8F' :
              '#FF5757'
          })
          setpaymentStatus({
            value: orderInfo.body.paymentStatus,
            label: orderInfo.body.paymentStatus === 'pending' ? 'No Pagado' : 'Pagado',
            color: orderInfo.body.paymentStatus === 'pending' ? '#FF5757' : '#5ACF8F'
          })
        }else {
          if( orderInfo.body.err && !orderInfo.body.err.details ) setError(orderInfo.body.err);
          if( orderInfo.body.err && orderInfo.body.err.details ) setError(orderInfo.body.err.details[0].message);
          if( orderInfo.body.hasOwnProperty("details") ) setError(orderInfo.body.details);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Intenta de nuevo");
        setLoading(false);
      }
    }

    fetchOrder();
  }, [ orderId ]);

  const formatDate = (date) => {
    let fecha = new Date(date);
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return fecha.getDate()+' '+months[fecha.getMonth()]+' '+fecha.getFullYear()+' - '+fecha.getHours()+':'+fecha.getMinutes()+':'+fecha.getSeconds();
  };

  const updateOrder = async (index, value) => {
    try {
      setUpdating(true);
      let body = {};
      if(index === 'status') body.status = value;
      if(index === 'paymentStatus') body.paymentStatus = value;

      const orderUpdated = await api.orders.update(orderId, body);
      if(orderUpdated.ok){
        setOrder(orderUpdated.body);
        props.notify("tc", "success", 'Pedido actualizado exitosamente.');
      }else {
        if( orderUpdated.body.err && !orderUpdated.body.err.details ) props.notify("tc", "danger", `Error al actualizar pedido: ${orderUpdated.body.err}.`, 10);
        if( orderUpdated.body.err && orderUpdated.body.err.details ) props.notify("tc", "danger", `Error al actualizar pedido: ${orderUpdated.body.err.details[0].message}.`, 10);
        if( orderUpdated.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al actualizar pedido: ${orderUpdated.body.details}.`, 10);
      }
      setUpdating(false);
    } catch (error) {
      console.log(error);
      setError("Intenta de nuevo");
      setUpdating(false);
    }
  }

  const sendWhatsapp = async (item) => {
    let whatsappText = 'Hola%2C+te+escribo+de+';
    whatsappText += item.accountName;
    window.open("https://api.whatsapp.com/send/?phone="+item.customerPhone+"&text="+whatsappText+"&app_absent=0", '_blank');
  }

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma('#a4a4a5');
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? color.alpha(0.3).css()
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: '#222a42',
        // cursor: isDisabled ? 'not-allowed' : 'default',
        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor:
        //     !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        // },
        ...dot(data.color)
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  if (loading === true) {
    return (
      <OrderLoading />
    );
  }else if(error !== null) {
    return (
      <ErrorMessage message={error} />
    )
  }else {
    return (
      <>
        <Row>
          <Col lg="4" className="pl-4">
            <CardTitle tag="h4" className="mt-auto mb-auto">Pedido No. {order.code}</CardTitle>
          </Col>
          <Col lg="8" className="justify-content-end row pr-0">
            <ReactToPrint
              trigger={() => (
                <Button
                  color="neutral"
                  className="btn-simple"
                >
                  <i className={`fas fa-${printIcon}`}></i>
                </Button>
              )}
              onBeforeGetContent={() => setprintIcon('spinner fa-spin')}
              onBeforePrint={() => setprintIcon('print')}
              content={() => componentRef}
              documentTitle={`Pedido No. ${order.code}`}
            />
            <div style={{ display: "none" }}>
              <OrderPrint
                ref={el => (componentRef = el)}
                order={order}
                formatDate={formatDate}
                currency={props.account.currency}
              />
            </div>
            <Button
              onClick={() => props.history.push('/orders')}
              disabled={loading}
              color="neutral"
              className="btn-simple"
            >
              Atrás
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <Col lg="12" md="12">
              <Card className="card-stats mb-3">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className={'info-icon text-center icon-'+(
                        order.status === 'pending' ? 'warning' :
                        order.status === 'accepted' ? 'info' :
                        order.status === 'delivering' ? 'secondary' :
                        order.status === 'completed' ? 'success' :
                        'danger'
                        )}>
                        <i className={(
                          order.status === 'pending' ? 'fa fa-exclamation' :
                          order.status === 'accepted' ? 'fa fa-smile' :
                          order.status === 'delivering' ? 'fa fa-motorcycle' :
                          order.status === 'completed' ? 'fa fa-check' :
                          'fa fa-times'
                        )} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Estado</p>
                        <Select
                          isDisabled={updating}
                          className="react-select info pt-1"
                          name="status"
                          value={status}
                          onChange={(value) => {
                            setStatus(value);
                            updateOrder('status', value.value);
                          }}
                          options={[
                            { value: "pending", label: "Pendiente", color: '#FFBD59' },
                            { value: "accepted", label: "Aceptado", color: '#0A91DF' },
                            { value: "delivering", label: "Entregando", color: '#CB6CE6' },
                            { value: "completed", label: "Completado", color: '#5ACF8F' },
                            { value: "canceled", label: "Cancelado", color: '#FF5757' },
                          ]}
                          placeholder="Estado..."
                          styles={ colourStyles }
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {order.hasOwnProperty("paymentStatus") && (  
              <Col lg="12" md="12">
                <Card className="card-stats mb-3">
                  <CardBody>
                    <Row>
                      <Col xs="5">
                        <div className={`info-icon text-center ${order.paymentStatus === 'pending' ? 'icon-danger' : 'icon-success'}`}>
                          <i className="fas fa-dollar-sign" />
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Pago</p>
                          {/* <CardTitle tag="h4" className={order.paymentStatus === 'pending' ? 'text-danger' : 'text-success'}>
                            {order.paymentStatus === 'pending' ? 'No Pagado' : 'Pagado'}
                          </CardTitle> */}
                          <Select
                            isDisabled={updating}
                            className="react-select info pt-1"
                            name="paymentStatus"
                            value={paymentStatus}
                            onChange={(value) => {
                              setpaymentStatus(value);
                              updateOrder('paymentStatus', value.value);
                            }}
                            options={[
                              { value: "pending", label: "No Pagado", color: '#FF5757' },
                              { value: "paid", label: "Pagado", color: '#5ACF8F' }
                            ]}
                            placeholder="Estado de Pago..."
                            styles={ colourStyles }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
            {order.payments && (
              order.payments.map( item => {
                if(item.paymentGateway.id === '617872c73900e72af33bcd08' || item.paymentGateway.id === '617871e1dcb7b02a9f6b754e'){
                  return (
                    <Col key={item.paymentGateway} lg="12" md="12">
                      <Card className="card-stats mb-3">
                        <CardBody>
                          <Row>
                            <Col xs="5">
                              <div className={`info-icon text-center icon-primary`}>
                                <i className={`tim-icons ${item.paymentGateway === '617872c73900e72af33bcd08' ? 'icon-money-coins' : 'icon-bank'}`} />
                              </div>
                            </Col>
                            <Col xs="7">
                              <div className="numbers">
                                <p className="card-category">Método de pago</p>
                                <CardTitle tag="h4">
                                  {item.paymentGateway.paymentGateway.name}
                                </CardTitle>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                };
              })
            )}
            <Col lg="12" md="12">
              <Card className="card-stats mb-3">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-calendar-60" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Fecha</p>
                        <CardTitle tag="h4">{formatDate(order.createdAt)}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="12" md="12">
              <Card className="card-stats mb-3">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-single-02" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Cliente</p>
                        <CardTitle tag="h4">{order.customerName}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="12" md="12">
              <Card className="card-stats mb-3">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                        <i className="fab fa-whatsapp" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Whatsapp</p>
                        <CardTitle tag="h4">
                          <a
                            href="#whatsapp"
                            onClick={() => sendWhatsapp(order)}
                          >
                            +{order.customerPhone}
                          </a>
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {order.shippingAddress &&
              <Col lg="12" md="12">
                <Card className="card-stats mb-3">
                  <CardBody>
                    <Row>
                      <Col xs="3">
                        <div className="info-icon text-center icon-success">
                          <i className="fa fa-map-marker"/>
                        </div>
                      </Col>
                      <Col xs="9">
                        <div className="numbers">
                          <p className="card-category">Dirección</p>
                          <p>{order.shippingAddress}</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            }
            {order.notes &&
              <Col lg="12" md="12">
                <Card className="card-stats mb-3">
                  <CardBody>
                    <Row>
                      <Col xs="3">
                        <div className="info-icon text-center icon-primary">
                          <i className="far fa-edit"/>
                        </div>
                      </Col>
                      <Col xs="9">
                        <div className="numbers">
                          <p className="card-category">Comentarios</p>
                          <p>{order.notes}</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            }
            {/* {order.payments && (
              order.payments.map( item => {
                if(item.paymentGateway !== '617872c73900e72af33bcd08' || item.paymentGateway !== '617871e1dcb7b02a9f6b754e'){
                  return (
                    <Col key={item.id} lg="12" md="12">
                      <Card className="card-stats mb-3">
                        <CardHeader>
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                color="neutral"
                                className="btn-icon btn-simple"
                              >
                                <i className="fas fa-print"></i>
                              </Button>
                            )}
                            content={() => componentRef}
                            documentTitle={`Voucher Pedido No. ${order.code}`}
                          />
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col xs="12">
                              <div id="voucherPrint" ref={el => (componentRef = el)}>
                                <p className="card-category mb-3 text-center">Comprobante de pago</p>
                                <CardTitle tag="h4" className="mb-0 text-center font-weight-600">{props.account.name}</CardTitle>
                                <p className="text-center">¡Gracias por tu compra <strong>{order.customerName}</strong>!</p>
                                <div style={{display: 'flex'}}>
                                  <Col xs="6">Fecha de Pago:</Col>
                                  <Col xs="6" className="text-right">{formatDate(item.createdAt)}</Col>
                                </div>
                                <div style={{display: 'flex'}}>
                                  <Col xs="6">Código de autorización:</Col>
                                  <Col xs="6" className="text-right">{item.gatewayData.transactionID}</Col>
                                </div>
                                <div style={{display: 'flex'}}>
                                  <Col xs="6">Tipo de tarjeta:</Col>
                                  <Col xs="6" className="text-right">{item.gatewayData.paymentType}</Col>
                                </div>
                                <div style={{display: 'flex'}}>
                                  <Col xs="6">No. de tarjeta:</Col>
                                  <Col xs="6" className="text-right">************{item.gatewayData.safeIdentifier}</Col>
                                </div>
                                <div style={{display: 'flex'}} className="font-weight-600">
                                  <Col xs="6">Total:</Col>
                                  <Col xs="6" className="text-right">
                                    <NumberFormat
                                      typeText={'span'}
                                      // className={'text-right'}
                                      number={item.total}
                                      prefix={`${props.account.currency.symbol}`}
                                    />
                                  </Col>
                                </div>

                                <p className="text-center mt-5">-- COPIA DE COMERCIO --</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                };
              })
            )} */}
          </Col>
          <Col lg="7">
            <Card className="card-tasks mb-0" style={{height: '700px'}}>
              <CardHeader>
                <h6 className="title d-inline">Productos</h6>
              </CardHeader>
              <CardBody>
                <div className="table-shopping table-full-width table-responsive" style={{maxHeight: '546px'}}>
                  <Table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Nombre</th>
                        <th>Precio</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map( item => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <div className="img-container">
                                {( item.defaultImage )
                                  ? <img src={item.defaultImage} alt={item.itemName} />
                                  : <img src={require("assets/img/item-placeholder.png").default} alt="expoceramica-placeholder" />
                                }
                              </div>
                            </td>
                            <td>
                              {item.itemSku ? `${item.itemSku} -` : ''} {item.itemName}
                              {item.variants &&
                                item.variants.map( variant => {
                                  let [value] = Object.entries(variant);
                                  return (
                                    <small key={value[0]}>
                                      <br/>
                                      {value[0]}: {value[1][0]}
                                    </small>
                                  );
                                })
                              }
                            </td>
                            <td>
                              <p className="text-muted">
                                <NumberFormat
                                  typeText={'span'}
                                  className={'mr-1'}
                                  number={item.price}
                                  prefix={`${props.account.currency.symbol}`}
                                />
                                ({item.quantity})
                              </p>
                            </td>
                            <td>
                              <NumberFormat
                                typeText={'span'}
                                number={item.total}
                                prefix={`${props.account.currency.symbol}`}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter>
                <div className="numbers text-right">
                  <p className="card-category">Total</p>
                  <CardTitle tag="h3">
                    <NumberFormat
                      typeText={'span'}
                      number={order.total}
                      prefix={`${props.account.currency.symbol}`}
                    />
                  </CardTitle>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
};

export default connect(mapStateToProps)(OrderDetail);