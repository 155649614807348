/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Auth
import Register from "views/pages/Register.js";
import Login from "views/pages/Login.js";

// Admin
import Dashboard from "views/Dashboard.js";
import Orders from "views/tables/Orders";
import Items from "views/tables/Items";
import Categories from "views/Categories";

const routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "fas fa-chart-pie",
    component: Dashboard,
    layout: "/",
  },
  {
    path: "orders",
    name: "Pedidos",
    rtlName: "جداول ممتدة",
    icon: "fas fa-shopping-cart",
    component: Orders,
    layout: "/",
  },
  {
    path: "items",
    name: "Productos",
    rtlName: "جداول ممتدة",
    icon: "fas fa-tag",
    component: Items,
    layout: "/",
  },
  {
    path: "categories",
    name: "Categorias",
    rtlName: "جداول ممتدة",
    icon: "fas fa-tasks",
    component: Categories,
    layout: "/",
  },
  // {
  //   path: "user-profile",
  //   name: "Configuración",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: "tim-icons icon-bag-16",
  //   component: Profile,
  //   layout: "/",
  // },
  {
    path: "/login",
    name: "Login",
    rtlName: "هعذاتسجيل الدخول",
    icon: "tim-icons icon-bag-16",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/signup",
    name: "Register",
    rtlName: "تسجيل",
    icon: "tim-icons icon-bag-16",
    component: Register,
    layout: "/auth",
  },
];

export default routes;
