const BASE_URL = process.env.REACT_APP_API_URL;

async function callApi(endpoint, options = {}) {
    //await simulateNetworkLatency();
  
    options.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token'),
    };
  
    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();
    
    return { ok: response.ok, status: response.status, body: data };
}

const api = {
    auth: {
        login(credentials) {
            return callApi(`/user/login`, {
                method: 'POST',
                body: JSON.stringify(credentials),
            });
        },
        signup(credentials) {
            return callApi(`/user/signup`, {
                method: 'POST',
                body: JSON.stringify(credentials),
            });
        }
    },
    categories: {
        list(query = '') {
            return callApi(`/itemcategory?sort=createdAt ASC${query}`); 
        },
        create(category) {
            return callApi(`/itemcategory`, {
              method: 'POST',
              body: JSON.stringify(category),
            });
        },
        show(categoryId) {
            return callApi(`/itemcategory/${categoryId}`);
        },
        update(categoryId, body) {
            return callApi(`/itemcategory/${categoryId}`, {
              method: 'PATCH',
              body: JSON.stringify(body),
            });
        },
        delete(categoryId) {
            return callApi(`/itemcategory/${categoryId}`, {
                method: 'DELETE'
            });
        },
    },
    items: {
        list(limit, skip, query) {
            let params = `limit=${limit}`;
            if(skip) params += `&skip=${skip}`;
            return callApi(`/item?${params}&sort="createdAt DESC"&populate=false${query}`); 
        },
        show(itemId) {
            return callApi(`/item/${itemId}`);
        },
        create(item) {
            return callApi(`/item`, {
                method: 'POST',
                body: JSON.stringify(item),
            });
        },
        update(itemId, body) {
            return callApi(`/item/${itemId}`, {
                method: 'PATCH',
                body: JSON.stringify(body),
            });
        },
        deleteItem(itemId) {
            return callApi(`/item/${itemId}`, {
                method: 'DELETE'
            });
        },
        createImage(images) {
            return callApi(`/itemimage`, {
                method: 'POST',
                body: JSON.stringify(images),
            });
        },
        updateImage(imageId, image) {
            return callApi(`/itemimage/${imageId}`, {
                method: 'PATCH',
                body: JSON.stringify(image),
            });
        },
        deleteImage(imageId) {
            return callApi(`/itemimage/${imageId}`, {
                method: 'DELETE'
            });
        },
    },
    itemImage: {
        list() {
            return callApi(`/itemimage`); 
        },
        create(itemImage) {
            return callApi(`/itemimage`, {
                method: 'POST',
                body: JSON.stringify(itemImage),
            });
        },
    },
    orders: {
        list(limit, skip, query) {
            let params = `limit=${limit}`;
            if(skip) params += `&skip=${skip}`;
            return callApi(`/order?${params}&sort="createdAt DESC"&populate=false${query}`); 
        },
        create(order) {
            return callApi(`/order`, {
              method: 'POST',
              body: JSON.stringify(order),
            });
        },
        show(orderId) {
            return callApi(`/order/${orderId}`);
        },
        update(orderId, body) {
            return callApi(`/order/${orderId}`, {
              method: 'PATCH',
              body: JSON.stringify(body),
            });
        },
        delete(orderId) {
            return callApi(`/order/${orderId}`, {
                method: 'DELETE'
            });
        },
    },
    account: {
        show(accountId) {
            return callApi(`/account/${accountId}`);
        },
        update(accountId, body) {
            return callApi(`/account/${accountId}`, {
                method: 'PATCH',
                body: JSON.stringify(body),
            });
        },
    },
    user: {
        show(userId) {
            return callApi(`/user/${userId}`);
        },
        update(userId, body) {
            return callApi(`/user/${userId}`, {
                method: 'PATCH',
                body: JSON.stringify(body),
            });
        },
    },
    settings: {
        country() {
            return callApi(`/country`);
        },
        currency() {
            return callApi(`/currency`);
        },
    },
    report: {
        orderTotal(dateFrom, dateTo) {
            return callApi(`/order/total?dateFrom=${dateFrom}&dateTo=${dateTo}`);
        },
        orderCount(dateFrom, dateTo) {
            return callApi(`/order/count?dateFrom=${dateFrom}&dateTo=${dateTo}`);
        },
    },
    paymentGateway: {
        login(paymentMethod) {
            return callApi(`/paymentgatewayclient`, {
                method: 'POST',
                body: JSON.stringify(paymentMethod),
            });
        },
        list() {
            return callApi(`/paymentgatewayclient?status=active`); 
        },
        update(paymentMethodId, body) {
            return callApi(`/paymentgatewayclient/${paymentMethodId}`, {
                method: 'PATCH',
                body: JSON.stringify(body),
            });
        },
    },
}

export default api;