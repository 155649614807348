import React from "react";
import ReactDOM from "react-dom";
import App from './App';

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";

ReactDOM.render(<App />, document.getElementById("root"));
