import React, { useEffect } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import api from "../api";

import Cropper from 'react-easy-crop';
import getCroppedImg from '../components/CustomUpload/cropImage';
import ImageUpload from "../components/CustomUpload/LogoUpload.js";
import logoDefault from "../assets/img/logo-default.png";
import ErrorMessage from "../components/ErrorMessage";

import PhoneInput from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es';
import 'react-phone-number-input/style.css';

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    account: state.account,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateAccount: (account) => dispatch({
      type: 'UPDATE_ACCOUNT',
      account: account
    }),
    updateUser: (user) => dispatch({
      type: 'UPDATE_USER',
      user: user
    })
  }
}

const User = (props) => {
  const [accountId, setaccountId] = React.useState("");
  const [accountName, setaccountName] = React.useState("");
  const [accountNickname, setaccountNickname] = React.useState("");
  const [accountMobileNumber, setaccountMobileNumber] = React.useState("");
  const [accountDescription, setaccountDescription] = React.useState("");
  const [accountLogo, setaccountLogo] = React.useState("");
  const [accountCountry, setaccountCountry] = React.useState("");
  const [accountCurrency, setaccountCurrency] = React.useState("");
  const [accountPlan, setaccountPlan] = React.useState("");
  const [accountPrice, setaccountPrice] = React.useState("");
  const [accountNameState, setaccountNameState] = React.useState("");
  const [accountMobileNumberState, setaccountMobileNumberState] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [updatingAccount, setUpdatingAccount] = React.useState(false);
  const [modalClassic, setModalClassic] = React.useState(false);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [imageToCrop, setImageToCrop] = React.useState({});
  const [cropping, setCropping] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [userFullName, setuserFullName] = React.useState("");
  const [userEmail, setuserEmail] = React.useState("");
  const [userMobileNumber, setuserMobileNumber] = React.useState("");
  const [userPassword, setuserPassword] = React.useState("");
  const [userFullNameState, setuserFullNameState] = React.useState("");
  const [userMobileNumberState, setuserMobileNumberState] = React.useState("");
  const [updatingUser, setUpdatingUser] = React.useState(false);

  const stateFunctions = {
    setaccountName: (value) => setaccountName(value),
    setaccountMobileNumber: (value) => setaccountMobileNumber(value),
    setaccountNameState: (value) => setaccountNameState(value),
    setaccountMobileNumberState: (value) => setaccountMobileNumberState(value),
    setuserFullName: (value) => setuserFullName(value),
    setuserMobileNumber: (value) => setuserMobileNumber(value),
    setuserFullNameState: (value) => setuserFullNameState(value),
    setuserMobileNumberState: (value) => setuserMobileNumberState(value),
  }

  useEffect(() => {
    async function fetchAccount() {
      try {
        setError(null);
        setLoading(true);
        const account = await api.account.show(props.account.id);

        if(account.ok){
          setaccountId(account.body.id);
          setaccountName(account.body.name);
          setaccountNickname(account.body.nickname);
          setaccountMobileNumber('+'+account.body.mobileNumber);
          setaccountDescription(account.body.description ? account.body.description : "");
          setaccountLogo({file: {name: account.body.name}, base64: account.body.logo ? account.body.logo : logoDefault});
          setaccountCountry(account.body.country.name);
          setaccountCurrency(account.body.currency.name);
          setaccountPlan(account.body.plan.name+(account.body.plan.name !== 'freemium' ? ' - 6 meses' : ''));
          setaccountPrice(account.body.plan.price);
          if(account.body.name) setaccountNameState("has-success");
          if(account.body.mobileNumber) setaccountMobileNumberState("has-success");
        }else {
          if( account.body.err && !account.body.err.details ) setError(account.body.err);
          if( account.body.err && account.body.err.details ) setError(account.body.err.details[0].message);
          if( account.body.hasOwnProperty("details") ) setError(account.body.details);
        }

        const user = await api.user.show(props.user.id);

        if(user.ok){
          setuserFullName(user.body.fullName);
          setuserMobileNumber('+'+user.body.mobileNumber);
          setuserEmail(user.body.email ? user.body.email : "");
          if(user.body.fullName) setuserFullNameState("has-success");
          if(user.body.mobileNumber) setuserMobileNumberState("has-success");
        }else {
          if( user.body.err && !user.body.err.details ) setError(user.body.err);
          if( user.body.err && user.body.err.details ) setError(user.body.err.details[0].message);
          if( user.body.hasOwnProperty("details") ) setError(user.body.details);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Intenta de nuevo");
        setLoading(false);
      }
    }

    fetchAccount();
  }, [ props.account.id, props.user.id ]);

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
    return true;
    }
    return false;
  };

  // function that verifies if value contains only numbers
  const verifyNumber = (value) => {
    if(value !== undefined)
      value = value.split('+');

    var numberRex = new RegExp("^[0-9.]+$");
    if (numberRex.test(value !== undefined ? value[1] : value)) {
      return true;
    }
    return false;
  };

  const change = (value, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (verifyLength(value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "number":
        if (verifyNumber(value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      default:
        break;
    }

    stateFunctions["set" + stateName](value);
  };

  const handleSubmit = async () => {
    if (accountNameState === "") {
      setaccountNameState("has-danger");
    }if (accountMobileNumberState === ""){
      setaccountMobileNumberState("has-danger");
    }else if(accountNameState === "has-success" && accountMobileNumberState === "has-success") {
      setUpdatingAccount(true);
      try{
        let whatsapp = null;
        if(accountMobileNumber) whatsapp = accountMobileNumber.split('+');

        const response = await api.account.update(props.account.id, {
          name: accountName,
          nickname: accountNickname ? accountNickname : null,
          description: accountDescription ? accountDescription : null,
          mobileNumber: whatsapp[1]
        });

        if(response.ok){
          props.notify("tc", "success", `Configuración de tu negocio actualizado exitosamente.`);
          response.body.country = response.body.country.id;
          props.updateAccount(response.body);
        }else {
          if( response.body.err && !response.body.err.details ) props.notify("tc", "danger", `Error al guardar configuración de tu negocio: ${response.body.err}.`, 10);
          if( response.body.err && response.body.err.details ) props.notify("tc", "danger", `Error al guardar configuración de tu negocio: ${response.body.err.details[0].message}.`, 10);
          if( response.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al guardar configuración de tu negocio: ${response.body.details}.`, 10);
        }
        setUpdatingAccount(false);
      } catch (error) {
        console.log(error);
        props.notify("tc", "danger", `Error al guardar configuración de tu negocio, intenta de nuevo.`, 10);
        setUpdatingAccount(false);
      }
    }
  }

  const setUploadImage = (file, base64) => {
    setImageToCrop({file, base64});
  }

  // function to open/close crop modal
  const toggleModalClassic = () => {
    setCropping(false);
    setModalClassic(!modalClassic);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const cropImage = async (image, file) => {
    try {
      setCropping(true);
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels
      )
      // console.log('donee', { croppedImage });
      if(croppedImage){
        let accountLogo = await api.account.update(props.account.id, {
          logo: croppedImage
        })

        if(accountLogo.ok){
          accountLogo.body.country = accountLogo.body.country.id;
          props.updateAccount(accountLogo.body);
          setaccountLogo({file, base64: croppedImage});
          props.notify("tc", "success", `Logo actualizado exitosamente.`);
          toggleModalClassic();
          setImageToCrop({});
        }else {
          if( accountLogo.body.err && !accountLogo.body.err.details ) props.notify("tc", "danger", `Error al guardar tu logo: ${accountLogo.body.err}.`, 10);
          if( accountLogo.body.err && accountLogo.body.err.details ) props.notify("tc", "danger", `Error al guardar tu logo: ${accountLogo.body.err.details[0].message}.`, 10);
          if( accountLogo.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al guardar tu logo: ${accountLogo.body.details}.`, 10);
        }
      }
      setCropping(false);
      
    } catch (error) {
      console.error(error);
      setCropping(false);
      props.notify("tc", "danger", `Error al guardar tu logo: Intenta otra imagen con tamaño máximo de 2000px.`, 10);
    }
  };

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSubmitUser = async () => {
    if (userFullNameState === "") {
      setuserFullNameState("has-danger");
    }if (userMobileNumberState === ""){
      setuserMobileNumberState("has-danger");
    }else if(userFullNameState === "has-success" && userMobileNumberState === "has-success") {
      setUpdatingUser(true);
      try{
        let whatsapp = null;
        if(userMobileNumber) whatsapp = userMobileNumber.split('+');

        let body = {
          fullName: userFullName,
          mobileNumber: whatsapp[1],
          email: userEmail ? userEmail : null,
        }
        if(userPassword) body.password = userPassword;

        const response = await api.user.update(props.user.id, body);

        if(response.ok){
          props.notify("tc", "success", `Usuario actualizado exitosamente.`);
          props.updateUser(response.body);
        }else {
          if( response.body.err && !response.body.err.details ) props.notify("tc", "danger", `Error al guardar usuario: ${response.body.err}.`, 10);
          if( response.body.err && response.body.err.details ) props.notify("tc", "danger", `Error al guardar usuario: ${response.body.err.details[0].message}.`, 10);
          if( response.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al guardar usuario: ${response.body.details}.`, 10);
        }
        setUpdatingUser(false);
      } catch (error) {
        console.log(error);
        props.notify("tc", "danger", `Error al guardar usuario, intenta de nuevo.`, 10);
        setUpdatingUser(false);
      }
    }
  }

  if (loading === true) {
    return (
      <p>
        Cargando...
      </p>
    );
  }else if(error !== null) {
    return (
      <ErrorMessage message={error} />
    )
  }else {
    return (
      <>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Configuración de tu negocio</h5>
              </CardHeader>
              <CardBody>
                {/* <Form> */}
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup className={`has-label ${accountNameState}`}>
                        <label>Negocio *</label>
                        <Input
                          defaultValue={accountName}
                          type="text"
                          onChange={(e) => change(e.target.value, "accountName", "length", 1)}
                        />
                        {accountNameState === "has-danger" ? (
                          <label className="error">Negocio es requerido.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup className={`has-label ${accountMobileNumberState}`}>
                        <label>Whatsapp *</label>
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="HN"
                          value={accountMobileNumber}
                          onChange={(phone) => change(phone, "accountMobileNumber", "number", 1)}
                          labels={es}
                          countryOptionsOrder={['HN', 'GT', 'SV']}
                        />
                        {accountMobileNumberState === "has-danger" ? (
                          <label className="error">Whatsapp es requerido.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Descripción de tu negocio</label>
                        <Input
                          cols="80"
                          value={accountDescription}
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                          onChange={(e) => setaccountDescription(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Nickname - nombre de tu enlace</label>
                        <Input
                          defaultValue={accountNickname}
                          type="text"
                          onKeyDown={ e => ( e.code === "Period" || e.code === "Space" ) && e.preventDefault() }
                          onChange={(e) => {
                            setaccountNickname(e.target.value)
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>País</label>
                        <Input
                          disabled
                          value={accountCountry}
                          type="text"
                          onChange={(e) => setaccountCountry(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Moneda</label>
                        <Input
                          disabled
                          value={accountCurrency}
                          type="text"
                          onChange={(e) => setaccountCurrency(e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Tipo de Plan</label>
                        <Input
                          disabled
                          value={accountPlan}
                          type="text"
                          onChange={(e) => setaccountPlan(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <label>Precio de Plan ($)</label>
                        <Input
                          disabled
                          prefix="L"
                          value={accountPrice}
                          type="text"
                          onChange={(e) => setaccountPrice(e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                {/* </Form> */}
              </CardBody>
              <CardFooter>
                <Button
                  disabled={accountNameState === 'has-danger' || accountMobileNumberState === 'has-danger' || updatingAccount}
                  className="btn-fill"
                  color="primary"
                  onClick={() => handleSubmit()}  
                >
                  { updatingAccount ? 'Guardando...' : 'Guardar' }
                </Button>
              </CardFooter>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="title">Datos de usuario</h5>
              </CardHeader>
              <CardBody>
                {/* <Form> */}
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup className={`has-label ${userFullNameState}`}>
                        <label>Nombre y Apellido *</label>
                        <Input
                          defaultValue={userFullName}
                          type="text"
                          onChange={(e) => change(e.target.value, "userFullName", "length", 1)}
                        />
                        {userFullNameState === "has-danger" ? (
                          <label className="error">Nombre y Apellido es requerido.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup className={`has-label ${userMobileNumberState}`}>
                        <label>Whatsapp/Usuario *</label>
                        {/* <Input
                          placeholder="+504 0000-0000"
                          type="text"
                          value={userMobileNumber}
                          onChange={(e) => change(e.target.value, "userMobileNumber", "number", 1)} /> */}
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="HN"
                          value={userMobileNumber}
                          onChange={(phone) => change(phone, "userMobileNumber", "number", 1)}
                          labels={es}
                          countryOptionsOrder={['HN', 'GT', 'SV']}
                        />
                        {userMobileNumberState === "has-danger" ? (
                          <label className="error">Número es requerido.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          value={userEmail}
                          type="text"
                          autoComplete="off"
                          onChange={(e) => setuserEmail(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Contraseña</label>
                        <Input
                          type="password"
                          autoComplete="off"
                          onChange={(e) => setuserPassword(e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                {/* </Form> */}
              </CardBody>
              <CardFooter>
                <Button
                  disabled={userFullNameState === 'has-danger' || userMobileNumberState === 'has-danger' || updatingUser}
                  className="btn-fill"
                  color="primary"
                  onClick={() => handleSubmitUser()}  
                >
                  { updatingUser ? 'Guardando...' : 'Guardar' }
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  {/* <img
                    alt="..."
                    className="avatar"
                    src={accountLogo}
                    onClick={() => {
                      toggleModalClassic();
                      setUploadImage(accountLogo);
                    }}
                  /> */}
                  <ImageUpload
                    avatar
                    addBtnColor="default"
                    changeBtnColor="default"
                    setImage={setUploadImage}
                    setModalClassic={setModalClassic}
                    logo={accountLogo}
                  />
                  <h5 className="title">{accountMobileNumber}</h5>
                  <p className="description">{accountName}</p>
                </div>
                <div className="card-description">
                  {accountDescription}
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <a href={`${process.env.REACT_APP_SHOP_URL}/${accountNickname ? accountNickname : accountId}`} rel="noreferrer" target="_blank">
                    <Button color="default" className="btn-simple">
                      <i className="fa fa-link" /> Visitar tienda
                    </Button>
                  </a>
                  <Button color="primary" className="btn-simple" onClick={() =>  {
                    navigator.clipboard.writeText(`${process.env.REACT_APP_SHOP_URL}/${accountNickname ? accountNickname : accountId}`);
                    props.notify("tc", "success", `Enlace copiado exitosamente.`);
                  }}>
                    <i className="far fa-clone" /> Copiar enlace
                  </Button>
                  {/* <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button> */}
                </div>
              </CardFooter>
            </Card>
          </Col>
          {/* Classic Modal */}
          <Modal
            modalClassName="modal-search"
            isOpen={modalClassic}
            toggle={toggleModalClassic}
            style={{transform: "none"}}
          >
            <div className="modal-header justify-content-center">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModalClassic}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Ajusta el lodo dentro del lienzo</h6>
            </div>
            <ModalBody className="text-center">
              <div style={{height: '350px'}}>
                <Cropper
                  minZoom={0.2}
                  image={imageToCrop.base64}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  restrictPosition={false}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                />
              </div>
            </ModalBody>
            <div className="modal-footer">
              <Button
                color="default"
                type="button"
                onClick={toggleModalClassic}
              >
                Cancelar
              </Button>
              <Button
                disabled={cropping}
                color="success"
                data-dismiss="modal"
                type="button"
                onClick={() => cropImage(imageToCrop.base64, imageToCrop.file)}
              >
                { cropping ? 'Cortando...' : 'Cortar' }
              </Button>
            </div>
          </Modal>
          {/* End Classic Modal */}
        </Row>
      </>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
