import React, { Component } from "react";
import { Link } from "react-router-dom";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import chroma from 'chroma-js';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  CardFooter,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";
import NumberFormat from "components/NumberFormat";
import api from "../../api";
import ItemsLoader from "../../components/Loaders/ItemsLoader";
import ErrorMessage from "../../components/ErrorMessage";

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      error: null,
      orders: [],
      limit: 25,
      skip: 0,
      singleSelect: null,
      dateFrom: '',
      dateTo: '',
      customer: '',
      customerPhone: '',
      orderStatus: null,
      paymentStatus: null
    };
  }

  componentDidMount() { this.fetchData(this.state.limit, 0) }
  // componentWillUnmount() {  }

  fetchData = async (limit, skip) => {
    try{
      this.setState({ orders: [], error: null, loading: true});
      if(skip) this.state.skip += skip;
      if(skip === 0) this.state.skip = 0;

      let query = '';
      if(this.state.dateFrom || this.state.dateTo || this.state.customer || this.state.customerPhone) query += `&where={`;
      
      if(this.state.dateFrom && this.state.dateTo) query += `"createdAt":{">=": ${this.state.dateFrom}, "<": ${this.state.dateTo}}`;
      
      if(this.state.dateFrom && !this.state.dateTo) query += `"createdAt":{">=": ${this.state.dateFrom} }`;
      if(this.state.dateTo && !this.state.dateFrom) query += `"createdAt":{"<": ${this.state.dateTo} }`;
      
      if(this.state.customer && (this.state.dateFrom || this.state.dateTo)) query += `,`;
      if(this.state.customer) query += `"customerName":{"contains":"${this.state.customer}" }`;
      
      if(this.state.customerPhone && (this.state.dateFrom || this.state.dateTo || this.state.customer)) query += `,`;
      if(this.state.customerPhone) query += `"customerPhone":{"contains":"${this.state.customerPhone}" }`;
      
      if(this.state.dateFrom || this.state.dateTo || this.state.customer || this.state.customerPhone) query += `}`;
      
      if(this.state.orderStatus) query += `&status=${this.state.orderStatus.value}`;
      if(this.state.paymentStatus) query += `&paymentStatus=${this.state.paymentStatus.value}`;

      const orders = await api.orders.list(limit, this.state.skip, query);

      if(orders.ok){
        this.setState({ orders: orders.body, loading: false });
      }else {
        if( orders.body.err && !orders.body.err.details ) this.setState({ error: orders.body.err, loading: false });
        if( orders.body.err && orders.body.err.details ) this.setState({ error: orders.body.err.details[0].message, loading: false });
        if( orders.body.hasOwnProperty("details") ) this.setState({ error: orders.body.details, loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: "Intenta de nuevo." });
    }
  }

  formatDate = (date) => {
    let fecha = new Date(date);
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return fecha.getDate()+' '+months[fecha.getMonth()]+' '+fecha.getFullYear()+' - '+fecha.getHours()+':'+fecha.getMinutes()+':'+fecha.getSeconds();
  };

  deleteItem = async (itemId) => {
    try{
      const order = await api.orders.deleteItem(itemId);
      if(order.ok){
        let new_items = this.state.orders.filter(element => element.id !== itemId);
        this.setState({orders: new_items});
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: "Intenta de nuevo." });
    }
  }

  sendWhatsapp = async (item) => {
    let whatsappText = 'Hola%2C+te+escribo+de+';
    whatsappText += item.accountName;
    window.open("https://api.whatsapp.com/send/?phone="+item.customerPhone+"&text="+whatsappText+"&app_absent=0", '_blank');
  }

  updateOrder = async (index, value, orderId) => {
    try {
      this.setState({updating: true});
      let body = {};
      if(index === 'status') body.status = value;

      const orderUpdated = await api.orders.update(orderId, body);
      if(orderUpdated.ok){
        this.setState({updating: false});
        this.props.notify("tc", "success", `Pedido No. ${orderUpdated.body.code} actualizado exitosamente.`);
      }else{
        if( orderUpdated.body.err && !orderUpdated.body.err.details ) this.props.notify("tc", "danger", `Error al actualizar pedido: ${orderUpdated.body.err}.`, 10);
        if( orderUpdated.body.err && orderUpdated.body.err.details ) this.props.notify("tc", "danger", `Error al actualizar pedido: ${orderUpdated.body.err.details[0].message}.`, 10);
        if( orderUpdated.body.hasOwnProperty("details") ) this.props.notify("tc", "danger", `Error al actualizar pedido: ${orderUpdated.body.details}.`, 10);

        this.setState({ updating: false });
      }
    } catch (error) {
      console.log(error);
      this.props.notify("tc", "danger", `Error al actualizar pedido, intenta de nuevo.`, 10);
      this.setState({ updating: false });
    }
  }

  dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma('#a4a4a5');
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? color.alpha(0.3).css()
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: '#222a42',
        // cursor: isDisabled ? 'not-allowed' : 'default',
        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor:
        //     !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        // },
        ...this.dot(data.color)
      };
    },
    input: styles => ({ ...styles, ...this.dot() }),
    placeholder: styles => ({ ...styles, ...this.dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...this.dot(data.color) }),
  };

  render() {
    if (this.state.loading === true) {
      return (
        <ItemsLoader />
      );
    }else if (this.state.error !== null) {
      return (
        <ErrorMessage message={this.state.error} />
      );
    }else {
      return (
        <>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Fecha desde...",
                          }}
                          timeFormat={false}
                          dateFormat="Y-MM-DD"
                          onChange={(e) => {
                            if(e._d){
                              e._d.setHours(0, 0, 0);
                              this.setState({ dateFrom: e._d.getTime() })
                            }
                          }}
                          value={this.state.dateFrom}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Fecha hasta...",
                          }}
                          timeFormat={false}
                          dateFormat="Y-MM-DD"
                          onChange={(e) => {
                            if(e._d){
                              e._d.setHours(23, 59, 59);
                              this.setState({ dateTo: e._d.getTime() })
                            }
                          }}
                          value={this.state.dateTo}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Input
                          type="text"
                          value={this.state.customer}
                          onChange={(e) => {
                            this.setState({ customer: e.target.value ? e.target.value : '' });
                          }}
                          placeholder={`Por cliente...`}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Input
                          type="text"
                          value={this.state.customerPhone}
                          onChange={(e) => {
                            this.setState({ customerPhone: e.target.value ? e.target.value : '' });
                          }}
                          placeholder={`Por WhatsApp...`}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="status"
                        value={this.state.orderStatus}
                        onChange={(value) => {
                          this.setState({ orderStatus: value });
                        }}
                        options={[
                          { value: "pending", label: "Pendiente" },
                          { value: "accepted", label: "Aceptado" },
                          { value: "delivering", label: "Entregando" },
                          { value: "completed", label: "Completado" },
                          { value: "canceled", label: "Cancelado" },
                        ]}
                        placeholder="Estado..."
                      />
                    </Col>
                    <Col md="3">
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="status"
                        value={this.state.paymentStatus}
                        onChange={(value) => {
                          this.setState({ paymentStatus: value });
                        }}
                        options={[
                          { value: "pending", label: "No Pagado" },
                          { value: "paid", label: "Pagado" }
                        ]}
                        placeholder="Pago..."
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-simple py-1"
                      onClick={() => this.fetchData(this.state.limit, 0)}
                      color="primary">
                      <i className="fas fa-filter"></i>{" "}
                      Filtrar busqueda
                    </Button>
                    <Button
                      className="btn-icon btn-simple"
                      onClick={(e) => {
                        this.setState({
                          dateFrom: '',
                          dateTo: '',
                          customer: '',
                          customerPhone: '',
                          orderStatus: null,
                          paymentStatus: null
                        }, () => {
                          this.fetchData(this.state.limit, 0);
                        });
                      }}
                      color="neutral">
                      <i className="fas fa-trash"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-simple"
                      onClick={() => this.fetchData(this.state.limit, 0)}
                      color="neutral">
                      <i className="fas fa-sync"></i>
                    </Button>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table className="table-shopping table table-hover mb-0">
                    <thead className="text-primary">
                      <tr>
                        <th>No.</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Whatsapp</th>
                        <th>Total</th>
                        <th className="text-center">Estado</th>
                        <th className="text-center">Pago</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.map( item => {
                        return (
                          <tr key={item.id}>
                            <td><Link to={`/order/${item.id}`}>{item.code}</Link></td>
                            <td><Link to={`/order/${item.id}`}>{ this.formatDate(item.createdAt) }</Link></td>
                            <td><Link to={`/order/${item.id}`}>{item.customerName}</Link></td>
                            <td>
                              <a
                                href="#whatsapp"
                                onClick={() => this.sendWhatsapp(item)}
                              >
                                +{item.customerPhone}
                              </a>
                            </td>
                            <td>
                              <NumberFormat
                                typeText={'span'}
                                number={item.total}
                                prefix={`${this.props.account.currency.symbol}`}
                              />
                            </td>
                            <td className="text-center" style={{width: '15%'}}>
                              <Select
                                isDisabled={this.state.updating}
                                className="react-select info"
                                name={item.id}
                                defaultValue={
                                  {
                                    value: item.status,
                                    label: item.status === 'pending' ? 'Pendiente' : 
                                      item.status === 'accepted' ? 'Aceptado' : 
                                      item.status === 'delivering' ? 'Entregando' :
                                      item.status === 'completed' ? 'Completado' :
                                      'Cancelado',
                                      color: item.status === 'pending' ? '#FFBD59' : 
                                      item.status === 'accepted' ? '#0A91DF' : 
                                      item.status === 'delivering' ? '#CB6CE6' :
                                      item.status === 'completed' ? '#5ACF8F' :
                                      '#FF5757'
                                  }
                                }
                                onChange={(value) => {
                                  this.updateOrder('status', value.value, item.id);
                                }}
                                options={[
                                  { value: "pending", label: "Pendiente", color: '#FFBD59' },
                                  { value: "accepted", label: "Aceptado", color: '#0A91DF' },
                                  { value: "delivering", label: "Entregando", color: '#CB6CE6' },
                                  { value: "completed", label: "Completado", color: '#5ACF8F' },
                                  { value: "canceled", label: "Cancelado", color: '#FF5757' },
                                ]}
                                placeholder="Estado..."
                                styles={ this.colourStyles }
                              />
                            </td>
                            <td className="text-center">
                              {item.hasOwnProperty("paymentStatus") && (
                                <Badge color={ item.paymentStatus === 'pending' ? 'danger' : 'success' } pill>
                                  { item.paymentStatus === 'pending' ? 'No Pagado' : 'Pagado' }
                                </Badge>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  <div className="float-right">
                    { (this.state.skip !== 0) &&
                      <Button
                        onClick={() => this.fetchData(this.state.limit, (this.state.limit * -1))}
                        className="btn-simple"
                        color="primary"
                      >
                        <i className="tim-icons icon-minimal-left"></i>
                      </Button>
                    }
                    { (this.state.orders.length === this.state.limit) &&
                      <Button 
                        onClick={() => this.fetchData(this.state.limit, this.state.limit)}
                        className="btn-simple"
                        color="primary"
                      >
                        <i className="tim-icons icon-minimal-right"></i>
                      </Button>
                    }
                  </div>
                  <Col lg="3" md="6" sm="12" style={{display: 'flex', alignItems: 'center'}}>
                    mostrar
                    <Select
                      className="react-select info my-1 mx-1 w-100"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={{ value: this.state.limit, label: this.state.limit }}
                      onChange={(element) => {
                        this.setState({limit: element.value});
                        this.fetchData(element.value, 0);
                      }}
                      options={[
                        { value: 25, label: "25" },
                        { value: 100, label: "100" },
                        { value: 250, label: "250" },
                      ]}
                      placeholder="25"
                    />
                    registros
                  </Col>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
};

export default connect(mapStateToProps)(Orders);
