import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import {
  Row,
  Card,
  CardBody,
  Col
} from 'reactstrap';


export default class DasboardLoader extends Component {
  render() {
    return (
      <Row>
        <Col lg="3" md="6">
          <Card className="card-stats" style={{minHeight: '137px'}}>
            <CardBody>
              <ContentLoader viewBox="0 0 320 137" height={137} width={290}>
                <rect x="210" y="21" rx="4" ry="4" width="104" height="6" />
                <rect x="180" y="41" rx="3" ry="3" width="135" height="7" />
                <circle cx="43" cy="43" r="43" />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" md="6">
          <Card className="card-stats" style={{minHeight: '137px'}}>
            <CardBody>
              <ContentLoader viewBox="0 0 320 137" height={137} width={290}>
                <rect x="210" y="21" rx="4" ry="4" width="104" height="6" />
                <rect x="180" y="41" rx="3" ry="3" width="135" height="7" />
                <circle cx="43" cy="43" r="43" />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
