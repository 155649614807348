import React, { useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import api from "../api";
import PayGateLogo from '../assets/img/paygate-logo.png';
import ErrorMessage from "../components/ErrorMessage";

const PaymentGateways = (props) => {
  // const [horizontalTabs, sethorizontalTabs] = React.useState("profile");
  // const [verticalTabs, setverticalTabs] = React.useState("profile");
  // const [verticalTabsIcons, setverticalTabsIcons] = React.useState("home");
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [hasPaygate, sethasPaygate] = React.useState(false);
  const [paygateId, setPaygateId] = React.useState(null);
  const [pageTabs, setpageTabs] = React.useState("efectivo");
  const [userName, setuserName] = React.useState("");
  const [userNameState, setuserNameState] = React.useState("");
  const [userPassword, setuserPassword] = React.useState("");
  const [userPasswordState, setuserPasswordState] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const stateFunctions = {
    setuserName: (value) => setuserName(value),
    setuserNameState: (value) => setuserNameState(value),
    setuserPassword: (value) => setuserPassword(value),
    setuserPasswordState: (value) => setuserPasswordState(value)
  }

  useEffect(() => {
    async function fetchAccount() {
      try {
        setError(null);
        setLoading(true);

        const paymentGateways = await api.paymentGateway.list();

        if(paymentGateways.ok){
          paymentGateways.body.forEach(element => {
            if(element.paymentGateway.id === '610c8d848d1ffb658a53494d'){
              setPaygateId(element.id);
              sethasPaygate(true); 
            }
          });
        }else {
          if( paymentGateways.body.err && !paymentGateways.body.err.details ) setError(paymentGateways.body.err);
          if( paymentGateways.body.err && paymentGateways.body.err.details ) setError(paymentGateways.body.err.details[0].message);
          if( paymentGateways.body.hasOwnProperty("details") ) setError(paymentGateways.body.details);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setError("Intenta de nuevo");
        setLoading(false);
      }
    }

    fetchAccount();
  }, []);

  // with this function we change the active tab for all the tabs in this page
  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      // case "horizontalTabs":
      //   sethorizontalTabs(tabName);
      //   break;
      // case "verticalTabsIcons":
      //   setverticalTabsIcons(tabName);
      //   break;
      case "pageTabs":
        setpageTabs(tabName);
        break;
      // case "verticalTabs":
      //   setverticalTabs(tabName);
      //   break;
      default:
        break;
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
    return true;
    }
    return false;
  };

  const change = (value, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (verifyLength(value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      default:
        break;
    }

    stateFunctions["set" + stateName](value);
  };

  const handleSubmit = async (gateway) => {
    if (userNameState === "") {
      setuserNameState("has-danger");
    }if (userPasswordState === ""){
      setuserPasswordState("has-danger");
    }else if(userNameState === "has-success" && userPasswordState === "has-success") {
      setSubmitting(true);
      try{
        let body = {
          data: {
            email: userName,
            password: userPassword
          }
        }
        if(gateway === 'paygate') body.paymentGateway = '610c8d848d1ffb658a53494d';

        const response = await api.paymentGateway.login(body);

        if(response.ok){
          props.notify("tc", "success", `Método de pago registrado exitosamente.`);
          if(gateway === 'paygate'){
            setPaygateId(response.body.id);
            sethasPaygate(true);
          }
          clearForm();
        }else {
          if( response.body.err && !response.body.err.details ) props.notify("tc", "danger", `Error al registrar método de pago: ${response.body.err}.`, 10);
          if( response.body.err && response.body.err.details ) props.notify("tc", "danger", `Error al registrar método de pago: ${response.body.err.details[0].message}.`, 10);
          if( response.body.hasOwnProperty("message") ) props.notify("tc", "danger", `Error al registrar método de pago: ${response.body.message}.`, 10);
        }
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        props.notify("tc", "danger", `Error al registrar método de pago, intenta de nuevo.`, 10);
        setSubmitting(false);
      }
    }
  }

  const handleSubmitLogout = async (gateway) => {
    setSubmitting(true);
    try{
      let body = {
        status: 'inactive'
      }
      let gatewayId = null;
      if(gateway === 'paygate') gatewayId = paygateId;

      const response = await api.paymentGateway.update(gatewayId, body);

      if(response.ok){
        props.notify("tc", "success", `Método de pago desenlazado exitosamente.`);
        if(gateway === 'paygate'){
          setPaygateId(null);
          sethasPaygate(false);
        }
      }else {
        if( response.body.err && !response.body.err.details ) props.notify("tc", "danger", `Error al desenlazar método de pago: ${response.body.err}.`, 10);
        if( response.body.err && response.body.err.details ) props.notify("tc", "danger", `Error al desenlazar método de pago: ${response.body.err.details[0].message}.`, 10);
        if( response.body.hasOwnProperty("message") ) props.notify("tc", "danger", `Error al desenlazar método de pago: ${response.body.message}.`, 10);
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      props.notify("tc", "danger", `Error al desenlazar método de pago, intenta de nuevo.`, 10);
      setSubmitting(false);
    }
  }

  const clearForm = () => {
    setuserName("");
    setuserNameState("");
    setuserPassword("");
    setuserPasswordState("");
  }

  if (loading === true) {
    return (
      <p>
        Cargando...
      </p>
    );
  }else if(error !== null) {
    return (
      <ErrorMessage message={error} />
    )
  }else {
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="card-plain card-subcategories">
                <CardHeader>
                  <CardTitle className="text-center mt-5" tag="h4">
                    Configura los métodos de pago que deseas utilizar para realizar tus cocbros
                  </CardTitle>
                  <br />
                </CardHeader>
                <CardBody>
                  {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}
                  <Nav
                    className="nav-pills-success nav-pills-icons justify-content-center"
                    pills
                  >
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        className={"active"}
                        style={{background: '#5ACF8F', backgroundImage: 'linear-gradient(to bottom left, #5ACF8F, #5ACF8F, #5ACF8F)'}}
                        onClick={(e) => changeActiveTab(e, "pageTabs", "efectivo")}
                      >
                        <i className="tim-icons icon-money-coins" />
                        Efectivo
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        className={"active"}
                        style={{background: '#5ACF8F', backgroundImage: 'linear-gradient(to bottom left, #5ACF8F, #5ACF8F, #5ACF8F)'}}
                        onClick={(e) => changeActiveTab(e, "pageTabs", "transferencia")}
                      >
                        <i className="tim-icons icon-bank" />
                        Transferencia Bancaria
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        className={pageTabs === "paygate" ? "active" : ""}
                        onClick={(e) => changeActiveTab(e, "pageTabs", "paygate")}
                      >
                        {/* <i className="tim-icons icon-istanbul" /> */}
                        <img src={PayGateLogo} alt="..." width="67%" className="my-2 tim-icons icon-istanbul" /><br/>
                        PayGate
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    className="tab-space tab-subcategories"
                    activeTab={pageTabs}
                  >
                    <TabPane tabId="efectivo">
                      
                    </TabPane>
                    <TabPane tabId="transferencia">
                      
                    </TabPane>
                    <TabPane tabId="paygate">
                      {!hasPaygate ? (
                        <>
                          <h4 className="text-center">Ingresa tus credenciales</h4>
                          <Row>
                            <Col className="pr-md-1" md="12">
                              <FormGroup className={`has-label ${userNameState}`}>
                                <label>Usuario *</label>
                                <Input
                                  type="text"
                                  onChange={(e) => change(e.target.value, "userName", "length", 1)}
                                />
                                {userNameState === "has-danger" ? (
                                  <label className="error">Usuario es requerido.</label>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-1" md="12">
                              <FormGroup className={`has-label ${userPasswordState}`}>
                                <label>Contraseña *</label>
                                <Input
                                  type="password"
                                  autoComplete="new-password"
                                  onChange={(e) => change(e.target.value, "userPassword", "length", 1)}
                                />
                                {userPasswordState === "has-danger" ? (
                                  <label className="error">Contraseña es requerido.</label>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="justify-content-center">
                            <Button
                              disabled={submitting}
                              className="btn-fill"
                              color="danger"
                              onClick={() => clearForm()}
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={userNameState === 'has-danger' || userPasswordState === 'has-danger' || submitting}
                              className="btn-fill"
                              color="primary"
                              onClick={() => handleSubmit('paygate')}
                            >
                              { submitting ? 'Inicinado sesión...' : 'Iniciar sesión' }
                            </Button>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Col className="text-center">
                            <h4>¡Ya tienes configurada tu cuenta!</h4>
                            Puedes iniciar a cobrar tus pedidos y recibir tus pagos a traves de PayGate.<br/>
                            <Button
                              disabled={submitting}
                              className="btn-fill my-5"
                              color="danger"
                              onClick={() => handleSubmitLogout('paygate', paygateId)}
                            >
                              { submitting ? 'Finalizar sesión...' : 'Finalizando sesión' }
                            </Button>
                          </Col>
                        </>
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
};

export default PaymentGateways;
