import React, { Component } from "react";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import api from "../api";
import CategoriesLoading from "../components/Loaders/CategoriesLoader";
import sendWhatsapp from "../components/WAMessage";
import ErrorMessage from "../components/ErrorMessage";
import imageDefault from "../assets/img/item-placeholder.png";

// Redux
import { connect } from 'react-redux';
import mixpanelApi from "../mixPanel";

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      categories: [],
      modalClassic: false,
      alert: null
    };
  }

  componentDidMount() { this.fetchData() }
  // componentWillUnmount() {  }

  fetchData = async () => {
    try{
      let query = '';
      if( (this.props.account.plan.code === '02') && (this.props.account.status === 'inactive') )
        query = '&where={"name":{"startsWith":"Todo"}}';

      const categories = await api.categories.list(query);
      
      if(categories.ok){
        this.setState({ categories: categories.body, loading: false });
      }else {
        if( categories.body.err && !categories.body.err.details ) this.setState({ error: categories.body.err, loading: false });
        if( categories.body.err && categories.body.err.details ) this.setState({ error: categories.body.err.details[0].message, loading: false });
        if( categories.body.hasOwnProperty("details") ) this.setState({ error: categories.body.details, loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: "Intenta de nuevo" });
    }
  }

  updateCategory = async (categoryId, isActive) => {
    try{
      const categoryUpdated = await api.categories.update(categoryId, {
        isActive: isActive
      });

      if(categoryUpdated.ok){
        let category = this.state.categories.find(item => item.id === categoryId);
        category.isActive = isActive;
        this.props.notify("tc", "success", `Categoría ${category.name}, actualizado exitosamente.`);
        this.setState({ categories: this.state.categories });
      }else {
        if( categoryUpdated.body.err && !categoryUpdated.body.err.details ) this.props.notify("tc", "danger", `Error al actualizar categoría: ${categoryUpdated.body.err}.`, 10);
        if( categoryUpdated.body.err && categoryUpdated.body.err.details ) this.props.notify("tc", "danger", `Error al actualizar categoría: ${categoryUpdated.body.err.details[0].message}.`, 10);
        if( categoryUpdated.body.hasOwnProperty("details") ) this.props.notify("tc", "danger", `Error al actualizar categoría: ${categoryUpdated.body.details}.`, 10);
      }
    } catch (error) {
      console.log(error);
      this.props.notify("tc", "danger", `Error al actualizar categoría, intenta de nuevo.`, 10);
    }
  }

  deleteCategory = async (categoryId) => {
    try{
      const category = await api.categories.delete(categoryId);
      if(category.ok){
        this.fetchData();
        this.successDelete(category);
      }
    } catch (error) {
      console.log(error);
      this.props.notify("tc", "danger", `Error al eliminar categoría, intenta de nuevo.`, 10);
    }
  }

  invalidAccount = () => {
    if ( this.props.account.plan.code === '01' ){
      return this.warningWithConfirmMessage("¿Te faltan categorías?", "No hay problema, Expoceramica Pro es sin límites.", true);
    }else if ( (this.props.account.plan.code === '02') && (this.props.account.status === 'inactive') ){
      return this.warningWithConfirmMessage("¡Tu suscripción ha vencido!", "Contáctanos para renovar tu suscripción.", false);
    }else if ( (this.props.account.plan.code === '02') && (this.props.account.status === 'active') ){
      this.props.history.push('/category/create');
    }
  }

  warningWithConfirmMessage = (title, msg, freemium) => {
    this.setState({
      alert:
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          mixpanelApi.general.upgradePlan(this.props.account.plan.name);
          sendWhatsapp(freemium, this.props.account.name)
        }}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText={freemium ? 'Pasarme a Pro' : 'Actualizar Plan'}
        cancelBtnText="Cancelar"
        showCancel
        btnSize=""
        cancelBtnCssClass="text-white"
        confirmBtnCssClass="text-white"
        focusConfirmBtn={false}
      >
        {msg}
      </ReactBSAlert>
    });
  };

  successDelete = (item) => {
    this.setState({
      alert: 
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Categoría eliminada!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Se elimino la categoría {item.name}
      </ReactBSAlert>
    
    });
  };

  hideAlert = () => {
    this.setState({ alert: null});
  };
    
  render() {
    if (this.state.loading === true) {
      return (
        <CategoriesLoading />
      );
    }else if (this.state.error !== null) {
      return (
        <ErrorMessage message={this.state.error} />
      );
    }else {
      return (
        <>
          {this.state.alert}
          <Row>
            <Col md="12" className="text-right pb-2">
              <Button
                color="primary"
                onClick={() => this.invalidAccount()}
              >
                Nuevo
              </Button>
            </Col>
          </Row>
          <Row>
            {this.state.categories.map( item => {
              return (
                <Col key={item.id} lg="3" md="6">
                  <Card className="card-stats" style={{minHeight: '234px'}}>
                    <CardBody>
                      <Row>
                        <Col className="ml-auto mr-auto col-auto mb-3">
                          {/* <div className="info-icon text-center icon-grey">
                            <i className="fas fa-image" />
                          </div> */}
                          <div style={{width: '50px', height: '50px', boxShadow: '0 0 2px rgba(52, 58, 67, 0.1), 0 2px 5px rgba(52, 58, 67, 0.08), 0 5px 15px rgba(52, 58, 67, 0.08)', borderRadius: '50%'}}>
                            <img
                              alt={item.name}
                              src={imageDefault}
                              style={{borderRadius: '50%'}}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ml-auto mr-auto col-auto">
                          <div className="numbers text-center">
                            <CardTitle className="mb-1" tag="h4">{item.name}</CardTitle>
                            <p className="card-category">{item.description}</p>
                            { (item.name !== 'Todo') && (
                              <div className="d-flex align-items-center justify-content-center">
                                <Button onClick={() => this.props.history.push(`/category/edit/${item.id}`)} className="btn-round btn-simple btn-icon" color="default">
                                <i className="tim-icons icon-pencil" />
                                </Button>
                                {/* <Button
                                  onClick={() => this.warningWithConfirmMessage(item)}
                                  className="btn-round btn-simple btn-icon" color="danger">
                                <i className="tim-icons icon-trash-simple" />
                                </Button> */}
                                <CustomInput
                                  type="switch"
                                  id={'switch-'+item.id}
                                  className="mt-n4 ml-2"
                                  onChange={(e) => this.updateCategory(item.id, e.target.checked)}
                                  defaultChecked={item.isActive}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fas fa-tag" /> {item.itemsLength} productos
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </>
      );
    }
  }
};

export default connect(mapStateToProps)(Categories);
