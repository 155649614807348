import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import routes from "routes.js";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Alert,
} from "reactstrap";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Profile from "../../views/Profile";
import CategoryForm from "../../views/forms/CategoryForm";
import CreateItemForm from "../../views/forms/CreateItem";
import UpdateItemForm from "../../views/forms/UpdateItem";
import OrderDetail from "../../views/OrderDetail";
import PaymentGateways from "../../views/PaymentGateways";

import logo from "assets/img/expoceramica-logo.png";

// Redux
import { connect } from 'react-redux';
import sendWhatsapp from "../../components/WAMessage";
import useGaTracker from "../../useGaTracker";
// import WatiWidget from "../../components/WatiWidget";

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

var ps;

const Admin = (props) => {
  useGaTracker();

  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);

  // React.useEffect(() => {
  //   WatiWidget('head');
  // }, []);
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            // component={prop.component}
            render={(props) => (<prop.component {...props} notify={notify} />)}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // const handleActiveClick = (color) => {
  //   setActiveColor(color);
  // };
  const handleMiniClick = () => {
    // if (document.body.classList.contains("sidebar-mini")) {
    //   setSidebarMini(false);
    // } else {
    //   setSidebarMini(true);
    // }
    // let options = {};
    // options = {
    //   place: "tr",
    //   message: notifyMessage,
    //   type: "primary",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7,
    // };
    // notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };
  const notify = (place, type, msg, dissmiss = 5) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: dissmiss,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor={'red'}
        logo={{
          outterLink: "#",
          text: "Expoceramica",
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
        notify={notify}
      />
      <div className="main-panel" ref={mainPanelRef} data={'red'}>
        <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
         <div className="content">
           { (props.account.status === 'inactive') &&
            <Alert color="warning">
              <span>!Tu suscripción ha vencido! {" "}
                <a
                  onClick={() => sendWhatsapp(false, props.account.name)}
                  className="font-weight-bold"
                  href="#subscription"
                >Renovar ahora</a>
              </span>
            </Alert>
          }
          <Switch>
            {getRoutes(routes)}
            <Route path='/user-profile' render={(props) => (<Profile {...props} notify={notify} />)} />
            <Route path='/category/create' render={(props) => (<CategoryForm {...props} notify={notify} />)} />
            <Route path='/category/edit/:categoryId' render={(props) => (<CategoryForm {...props} notify={notify} />)} />
            <Route path='/item/create' render={(props) => (<CreateItemForm {...props} notify={notify} />)} />
            <Route path='/item/edit/:itemId' render={(props) => (<UpdateItemForm {...props} notify={notify} />)} />
            <Route path='/order/:orderId' render={(props) => (<OrderDetail {...props} notify={notify} />)} />
            <Route path='/payment-methods' render={(props) => (<PaymentGateways {...props} notify={notify} />)} />
            <Redirect from="*" to="/dashboard" />
          </Switch>
         </div>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid admin />
          )
        }
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Admin);
