import React from "react";
import { useParams } from "react-router";
import classnames from "classnames";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Label,
  Modal,
  ModalBody,
  CustomInput,
} from "reactstrap";

// core components
import api from "../../api";
import ImageItemUpload from "../../components/CustomUpload/ImageItemUpload.js";
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../components/CustomUpload/cropImage';
import sendWhatsapp from "../../components/WAMessage";
import ErrorMessage from "../../components/ErrorMessage";
import ReactTags from 'react-tag-autocomplete';
import 'assets/css/react-tag.css';

// Redux
import { connect } from 'react-redux';

const mapStateToProps = (state)=>{
  return{
    itemImages: state.itemImages,
    account: state.account
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setItemImages: (images) => dispatch({
      type: 'SET_ITEM_IMAGE',
      itemImages: images
    }),
    addItemImage: (image) => dispatch({
      type: 'ADD_ITEM_IMAGE',
      newItem: image
    }),
    updateItemImage: (item) => dispatch({
      type: 'UPDATE_ITEM_IMAGE',
      image: item
    }),
    removeItemImage: (item) => dispatch({
      type: 'REMOVE_ITEM_IMAGE',
      image: item
    }),
    emptyItemImages: () => dispatch({
      type: 'EMPTY_ITEM_IMAGE'
    })
  }
}

const ItemForm = (props) => {
  const { itemId } = useParams();
  const [alert, setAlert] = React.useState(null);
  const [isActiveItem, setisActiveItem] = React.useState(null);
  const [skuItem, setskuItem] = React.useState("");
  const [nameItem, setnameItem] = React.useState("");
  const [descriptionItem, setdescriptionItem] = React.useState("");
  const [priceItem, setpriceItem] = React.useState("");
  const [skuItemState, setskuItemState] = React.useState("");
  const [nameItemState, setnameItemState] = React.useState("");
  // const [descriptionItemState, setdescriptionItemState] = React.useState("");
  const [priceItemState, setpriceItemState] = React.useState("");
  const [priceItemFocus, setpriceItemFocus] = React.useState("");
  const stateFunctions = {
    setskuItem: (value) => setskuItem(value),
    setnameItem: (value) => setnameItem(value),
    setdescriptionItem: (value) => setdescriptionItem(value),
    setpriceItem: (value) => setpriceItem(value),
    setskuItemState: (value) => setskuItemState(value),
    setnameItemState: (value) => setnameItemState(value),
    // setdescriptionItemState: (value) => setdescriptionItemState(value),
    setpriceItemState: (value) => setpriceItemState(value),
  }
  const [categories, setCategories] = React.useState([]);
  const [itemCategories, setitemCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errorState, setErrorState] = React.useState(false);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [imageToCrop, setImageToCrop] = React.useState({});
  const [modalClassic, setModalClassic] = React.useState(false);
  const [itemImagesToDelete, setitemImagesToDelete] = React.useState([]);
  const [hasVariant, sethasVariant] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [variants, setVariants] = React.useState([]);
  const reactTags = React.createRef();
  
  // function to open/close crop modal
  const toggleModalClassic = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setModalClassic(!modalClassic);
  };

  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9.]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "password":
        if (verifyLength(event.target.value, 1)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "number":
        if (verifyNumber(event.target.value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      default:
        break;
    }
    stateFunctions["set" + stateName](event.target.value);
  };

  React.useEffect(() => {
    async function fetchItem() {
      try {
        setError(null);
        setLoading(true);
        let itemCats = [];
        const itemInfo = await api.items.show(itemId);
  
        if(itemInfo.ok){
          setisActiveItem(itemInfo.body.isActive);
          setskuItem(itemInfo.body.sku);
          setnameItem(itemInfo.body.name);
          setdescriptionItem(itemInfo.body.description);
          setpriceItem(itemInfo.body.price);
          if(itemInfo.body.sku) setskuItemState("has-success");
          if(itemInfo.body.name) setnameItemState("has-success");
          if(itemInfo.body.price) setpriceItemState("has-success");
          if(itemInfo.body.images){
            itemInfo.body.images.forEach(element => {
              element.isDefault = element.url === itemInfo.body.defaultImage ? true : false;
              element.file = {name: element.id, uploaded: true};
              element.base64 = element.url;
              element.transaction = 'none';
            });
            
            addImage(itemInfo.body.images);
          }
          if(itemInfo.body.variants){
            if(itemInfo.body.variants !== null && itemInfo.body.variants.length !== 0){
              let count = 0;
              sethasVariant(true);
              itemInfo.body.variants.forEach(element => {
                let values = Object.entries(element);
                let optionArr = [];
                values[0][1].forEach(option => {
                  let obj = { id: option, name: option}
                  option = obj;
                  optionArr.push(obj);
                });
                element.id = count;
                element.label = values[0][0];
                element.options = optionArr;
                count += 1;
              });
              setCounter(count);
              setVariants(itemInfo.body.variants);
            }
          }
        
          itemInfo.body.categories.forEach(element => {
            itemCats.push(element.id);
          });
          setitemCategories(itemCats);
          fecthCategories(itemInfo.body.categories);
        }else{
          if( itemInfo.body.err && !itemInfo.body.err.details ) setError(itemInfo.body.err);
          if( itemInfo.body.err && itemInfo.body.err.details ) setError(itemInfo.body.err.details[0].message);
          if( itemInfo.body.hasOwnProperty("details") ) setError(itemInfo.body.details);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError("Intenta de nuevo");
        setLoading(false);
      }
    }
    async function fecthCategories(itemsCategory) {
      try {
        setCategories([]);
        const categoryList = await api.categories.list();

        if(categoryList.ok){
          categoryList.body.forEach(element => {
            let equals_cat = itemsCategory.find(cat => cat.id === element.id);
            if(equals_cat) element.checked = true;
            else element.checked = false;
          });
          setCategories(categoryList.body);
        }else{
          if( categoryList.body.err && !categoryList.body.err.details ) setError(categoryList.body.err);
          if( categoryList.body.err && categoryList.body.err.details ) setError(categoryList.body.err.details[0].message);
          if( categoryList.body.hasOwnProperty("details") ) setError(categoryList.body.details);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Intenta de nuevo");
        setLoading(false);
      }
    }

    fetchItem();

    window.addEventListener('unload', handleEndConcert);
    return () => {
      window.removeEventListener('unload', handleEndConcert);
      handleEndConcert();
    }
  }, [ itemId ]);

  const handleEndConcert = () => {
    props.emptyItemImages();
  }

  const onCheckCategory = (e, category) => {
    if(e.target.checked){
      setitemCategories([...itemCategories, category.id]);
    }else if(!e.target.checked){
      let new_categories = itemCategories.filter(element => category.id !== element);
      setitemCategories(new_categories);
    }
  }

  const setUploadImage = (file, base64) => {
    setImageToCrop({file, base64});
  }

  const addImage = (images) => {
    props.setItemImages(images);
  }

  const removeImage = (image) => {
    // Se agrega la imagen al arreglo de elimiar solamente si esta subida en el servidor
    let imageVerification = props.itemImages.find(item => image.file.name === item.file.name && item.file.uploaded === true);
    if(imageVerification)
      setitemImagesToDelete(itemImagesToDelete => [...itemImagesToDelete, {id: image.file.name}]);
    
    props.removeItemImage(image);
  }

  const defaultImage = (image) => {
    let oldDefaultImage = props.itemImages.find(item => item.isDefault === true);
    if(oldDefaultImage){
      oldDefaultImage.isDefault = false;
      if(oldDefaultImage.transaction === 'makeDefault') oldDefaultImage.transaction = 'none';
    }

    let newDefaultImage = props.itemImages.find(item => item.base64 === image.base64);
    if(newDefaultImage) {
      let index = props.itemImages.findIndex(item => item.base64 === image.base64)
      props.itemImages[index].isDefault = true;
      if(props.itemImages[index].transaction === 'none')
        props.itemImages[index].transaction = 'makeDefault';

      props.updateItemImage(props.itemImages[index]);
    }
  }

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = async (image, file) => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels
      )
      // console.log('donee', { croppedImage });
      let imageIndex = props.itemImages.findIndex(item => file.name === item.file.name);

      if(imageIndex  !== -1){
        let imageToReplace = props.itemImages[imageIndex];
        imageToReplace['base64'] = croppedImage;
        props.updateItemImage(imageToReplace);
        toggleModalClassic();
        setImageToCrop({});
      }else {
        file.uploaded = false;
        let image = {
          isDefault: props.itemImages.length === 0 ? true : false,
          transaction: 'add',
          file: file,
          base64: croppedImage
        }
        props.addItemImage(image);
        toggleModalClassic();
        setImageToCrop({});
      }

    } catch (error) {
      console.error(error);
      props.notify("tc", "danger", `Error al cortar imagen: Intenta de nuevo.`, 10);
    }
  };

  const invalidAccount = () => {
    if ( (props.account.plan.code === '02') && (props.account.status === 'inactive') ){
      warningWithConfirmMessage("¡Tu suscripción ha vencido!", "Contáctanos para renovar tu suscripción.", false);
    }else {
      updateItem();
    }
  }

  const warningWithConfirmMessage = (title, msg, freemium) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => sendWhatsapp(freemium, props.account.name)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Actualizar Plan"
        cancelBtnText="Cancelar"
        showCancel
        btnSize=""
        cancelBtnCssClass="text-white"
        confirmBtnCssClass="text-white"
        focusConfirmBtn={false}
      >
        {msg}
      </ReactBSAlert>
    );
  }

  const hideAlert = () => {
    setAlert(null);
  };

  const updateItem = async () => {
    if (skuItemState === "") {
      setskuItemState("has-danger");
    }if (nameItemState === ""){
      setnameItemState("has-danger");
    }if (priceItemState === ""){
      setpriceItemState("has-danger");
    }else if(skuItemState === "has-success" && nameItemState === "has-success" && priceItemState === "has-success") {
      try{ 
        setUpdating(true);
        let updateJSON = {
          sku: skuItem,
          name: nameItem,
          description: descriptionItem ? descriptionItem : null,
          price: priceItem,
          categories: itemCategories,
          isActive: isActiveItem
        }
        let itemVariants = [];

        if(hasVariant){
          variants.forEach(element => {
            let optArray = [];
            element.options.forEach(option => {
              optArray.push(option.name);
            });
            let body = { [element.label] : optArray }
            itemVariants.push(body);
          });

          updateJSON.variants = itemVariants;
        }else {
          updateJSON.variants = [];
        }
        
        const item = await api.items.update(itemId, updateJSON);

        if(item.ok){
          createImages(item.body);
        }else{
          if( item.body.err && item.body.err === "Item SKU already exists") props.notify("tc", "danger", `Error al actualizar item: Código/SKU ya existe, debes de ingresar un Código/SKU único.`, 10);
          else if( item.body.err && !item.body.err.details ) props.notify("tc", "danger", `Error al actualizar item: ${item.body.err}.`, 10);
          else if( item.body.err && item.body.err.details ) props.notify("tc", "danger", `Error al actualizar item: ${item.body.err.details[0].message}.`, 10);
          else if( item.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al actualizar item: ${item.body.details}.`, 10);
          setUpdating(false);
        }
      } catch (error) {
        console.log(error);
        setError("Intenta de nuevo");
        setUpdating(false);
      }
    }
  };

  const createImages = async (item) => {
    try{ 
      if(props.itemImages){
        for(var i = 0; i < props.itemImages.length; i++) {
          if(props.itemImages[i].transaction === 'update' || props.itemImages[i].transaction === 'makeDefault'){
            const imageUpdate = await api.items.updateImage(props.itemImages[i].id, {
              isDefault: props.itemImages[i].isDefault
            });
            if(!imageUpdate.ok){
              props.notify("tc", "danger", `Error al actualizar imagen, intente de nuevo.`);
              setErrorState(true);
            }
          }else if(props.itemImages[i].transaction === 'add'){
            const imageCreate = await api.items.createImage({
              item: item.id,
              image: props.itemImages[i].base64,
              isDefault: props.itemImages[i].isDefault
            });
            if(!imageCreate.ok){
              props.notify("tc", "danger", `Error al guardar imagen, intente de nuevo.`);
              setErrorState(true);
            }
          }
        };
      }
      if(itemImagesToDelete){
        for(var x = 0; x < itemImagesToDelete.length; x++) {
          const imageDelete = await api.items.deleteImage(itemImagesToDelete[x].id);
          if(!imageDelete.ok){
            props.notify("tc", "danger", `Error al eliminar imagen, intente de nuevo.`);
            setErrorState(true);
          }
        };
      }
      setUpdating(false);
      if(!errorState){
        props.notify("tc", "success", 'Item '+item.name+' actualizado exitosamente.');
        props.history.push('/items');
      }
    } catch (error) {
      console.log(error);
      props.notify("tc", "danger", `Error al guardar imagen, intente de nuevo.`);
      setUpdating(false);
    }
  };

  if (loading === true) {
    return (
      <p>
        Cargando...
      </p>
    );
  }else if(error !== null){
    return (
      <ErrorMessage message={error} />
    )
  }else{
    return (
      <>
        {alert}
        <Row>
          <Col md="12" className="text-right pb-2">
              <Button
                onClick={() => props.history.push('/items')}
                color="neutral"
                className="btn-simple"
              >
                Atrás
              </Button>
              <Button
                disabled={skuItemState === 'has-danger' || nameItemState === 'has-danger' || priceItemState === 'has-danger' || updating}
                color="primary"
                onClick={invalidAccount}
              >
                {updating ? 'Guardando' : 'Guardar'}
              </Button>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form id="RegisterValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Detalle</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <div className="d-flex align-items-center">
                      <CustomInput
                        type="switch"
                        id={'switch-1'}
                        className="mt-n4"
                        onChange={(e) => setisActiveItem(e.target.checked)}
                        defaultChecked={isActiveItem}
                      />
                      <span>{ isActiveItem ? 'Activo' : 'Inactivo' }</span>
                    </div>
                  </FormGroup>
                  <FormGroup className={`has-label ${skuItemState}`}>
                    <label>Código/SKU *</label>
                    <Input
                      name="sku"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => change(e, "skuItem", "length", 1)}
                      defaultValue={skuItem}
                    />
                    {skuItemState === "has-danger" ? (
                      <label className="error">Código es requerido.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${nameItemState}`}>
                    <label>Nombre *</label>
                    <Input
                      name="name"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => change(e, "nameItem", "length", 1)}
                      defaultValue={nameItem}
                    />
                    {nameItemState === "has-danger" ? (
                      <label className="error">Nombre es requerido.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label`} >
                    <label>Descripción</label>
                    <Input
                      cols="80"
                      rows="4"
                      name="description"
                      type="textarea"
                      autoComplete="off"
                      onChange={(e) => stateFunctions["setdescriptionItem"](e.target.value)}
                      defaultValue={descriptionItem}
                    />
                  </FormGroup>
                  <div>
                    <label>Precio *</label>
                    <InputGroup
                      className={classnames(priceItemState, {
                        "input-group-focus": priceItemFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-coins" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="number"
                        type="text"
                        onChange={(e) => change(e, "priceItem", "number")}
                        onFocus={(e) => setpriceItemFocus(true)}
                        onBlur={(e) => setpriceItemFocus(false)}
                        defaultValue={priceItem}
                      />
                    </InputGroup>
                    {priceItemState === "has-danger" ? (
                      <label className="error">Precio es requerido y tipo numérico.</label>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Form>
          </Col>
          <Col md="6">
            <Card className="card-tasks"style={{height: '447px'}}>
              <CardHeader>
                <CardTitle tag="h4">Categorías</CardTitle>
              </CardHeader>
              <CardBody>
                <Col className="table-full-width table-responsive checkbox-radios" style={{maxHeight: '370px'}}>
                    {categories.map( item => {
                      return (
                        <FormGroup
                          key={item.id}
                          check
                          className="py-1"
                          disabled={item.name === 'Todo' ? true : false}
                        >
                          <Label
                            check
                            onChange={e => onCheckCategory(e, item)}
                          >
                            <Input
                              value={item.id}
                              name="optionCheckboxes"
                              type="checkbox"
                              disabled={item.name === 'Todo' ? true : false}
                              defaultChecked={item.checked}
                            />
                            <span className="form-check-sign" />
                            {item.name}
                          </Label>
                        </FormGroup>
                      );
                    })}
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Variantes</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <div className="d-flex align-items-center">
                    <CustomInput
                      type="switch"
                      id={'switch-2'}
                      className="mt-n4"
                      onChange={(e) => {
                        sethasVariant(e.target.checked);
                        if(e.target.checked){
                          setCounter(0);
                          setVariants([{
                            id: counter,
                            label: "",
                            options: []
                          }]);
                        }
                      }}
                      defaultChecked={hasVariant}
                    />
                    <span>¿Este producto tiene variantes? Ejemplo: Tallas, Colores, etc...</span>
                  </div>
                </FormGroup>
                { hasVariant && (
                  <>
                    {variants.map(element => {
                      return (
                        <Row key={element.id}>
                          <Col md="3">
                            <FormGroup>
                              <label>Nombre</label>
                              <Input
                                type="text"
                                autoComplete="off"
                                defaultValue={element.label}
                                onChange={(e) => {
                                  element.label = e.target.value;
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="8">
                            <FormGroup>
                              <label>Agregar opciones</label>
                              <ReactTags
                                ref={reactTags}
                                tags={element.options}
                                onDelete={(i) => {
                                  element.options.splice(i,1);
                                }}
                                onAddition={(tagsinput) => {
                                  tagsinput.id = tagsinput.name;
                                  element.options.push(tagsinput);
                                }}
                                placeholderText="Presiona ENTER para agregarlo"
                                allowBackspace={false}
                                allowNew={true}
                                minQueryLength={1}
                              />
                            </FormGroup>
                          </Col>
                          <Button
                            onClick={() => {
                              setVariants(variants.filter(item => element.id !== item.id));
                            }}
                            className="btn-round btn-simple btn-icon mt-auto mb-2" color="danger">
                            <i className="tim-icons icon-trash-simple" />
                          </Button>
                        </Row>
                      );
                    })}
                    <Button
                      disabled={updating}
                      color="primary"
                      className="btn-simple"
                      onClick={() => {
                        let count = counter + 1;
                        setCounter(count);
                        setVariants([...variants, {
                          id: count,
                          label: "",
                          options: []
                        }])
                      }}
                    >
                      Agregar variante
                    </Button>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="3" sm="3">
                    <ImageItemUpload
                      addBtnColor="primary"
                      changeBtnColor="default"
                      setImage={setUploadImage}
                      setModalClassic={setModalClassic}
                    />
                  </Col>
                  {props.itemImages.map( item => {
                    return (
                      <Col key={item.file.name} className="fileinput text-center" md="2" sm="2">
                        <div className={"thumbnail"}>
                          <img
                            src={item.base64}
                            alt={item.file.name}
                            onClick={() => {
                              setUploadImage(item.file, item.base64);
                              toggleModalClassic();
                            }}
                          />
                        </div>
                        <Button
                          className="btn-sm"
                          color="primary"
                          onClick={() => {
                            setUploadImage(item.file, item.base64);
                            toggleModalClassic();
                          }}
                        >
                          <i className="fa fa-crop"></i>
                        </Button>
                        <Button
                          className="btn-sm"
                          color="success"
                          onClick={() => {
                            if(!item.isDefault){
                              defaultImage(item)
                            }
                          }}
                        >
                          { (item.isDefault === true) 
                            ? <i className={'fas fa-star'}></i>
                            : <i className={'far fa-star'}></i> 
                          }
                        </Button>
                        <Button
                          className="btn-sm"
                          color="danger"
                          onClick={() => removeImage(item)}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </Col>
                    );
                  })}
                  {/* Classic Modal */}
                  <Modal
                    modalClassName="modal-search"
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    style={{transform: "none"}}
                  >
                    <div className="modal-header justify-content-center">
                      <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassic}
                      >
                        <i className="tim-icons icon-simple-remove" />
                      </button>
                      <h6 className="title title-up">Ajusta la imagen dentro del lienzo</h6>
                    </div>
                    <ModalBody className="text-center">
                      <div style={{height: '350px'}}>
                        <Cropper
                          minZoom={0.2}
                          image={imageToCrop.base64}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          restrictPosition={false}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <Button
                        color="default"
                        type="button"
                        onClick={toggleModalClassic}
                      >
                        Atrás
                      </Button>
                      <Button
                        color="success"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => cropImage(imageToCrop.base64, imageToCrop.file)}
                      >
                        Cortar
                      </Button>
                    </div>
                  </Modal>
                  {/* End Classic Modal */}
                </Row>
              </CardBody>
            </Card>
            {/* end card */}
          </Col>
        </Row>
      </>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
