import React from "react";
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Label,
  Modal,
  ModalBody,
  CustomInput,
} from "reactstrap";

// core components
import ImageItemUpload from "../../components/CustomUpload/ImageItemUpload.js";
import Cropper from 'react-easy-crop';
import api from "../../api";
import getCroppedImg from '../../components/CustomUpload/cropImage';
import mixpanelApi from "../../mixPanel.js";
import ErrorMessage from "../../components/ErrorMessage.js";
import ReactTags from 'react-tag-autocomplete';
import 'assets/css/react-tag.css';

// Redux
import { connect } from 'react-redux';

const mapStateToProps = (state)=>{
  return{
    itemImages: state.itemImages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addItemImage: (image) => dispatch({
      type: 'ADD_ITEM_IMAGE',
      newItem: image
    }),
    updateItemImage: (item) => dispatch({
      type: 'UPDATE_ITEM_IMAGE',
      image: item
    }),
    removeItemImage: (item) => dispatch({
      type: 'REMOVE_ITEM_IMAGE',
      image: item
    }),
    emptyItemImages: () => dispatch({
      type: 'EMPTY_ITEM_IMAGE'
    })
  }
}

const ItemForm = (props) => {
  const [skuItem, setskuItem] = React.useState("");
  const [nameItem, setnameItem] = React.useState("");
  const [descriptionItem, setdescriptionItem] = React.useState("");
  const [priceItem, setpriceItem] = React.useState("");
  const [skuItemState, setskuItemState] = React.useState("");
  const [nameItemState, setnameItemState] = React.useState("");
  const [priceItemState, setpriceItemState] = React.useState("");
  const [priceItemFocus, setpriceItemFocus] = React.useState("");
  const stateFunctions = {
    setskuItem: (value) => setskuItem(value),
    setnameItem: (value) => setnameItem(value),
    setdescriptionItem: (value) => setdescriptionItem(value),
    setpriceItem: (value) => setpriceItem(value),
    setskuItemState: (value) => setskuItemState(value),
    setnameItemState: (value) => setnameItemState(value),
    setpriceItemState: (value) => setpriceItemState(value),
  }
  const [categories, setCategories] = React.useState([]);
  const [itemCategories, setitemCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [imageToCrop, setImageToCrop] = React.useState({});
  const [modalClassic, setModalClassic] = React.useState(false);
  const [hasVariant, sethasVariant] = React.useState(false);
  let [counter, setCounter] = React.useState(0);
  const [variants, setVariants] = React.useState([]);
  const reactTags = React.createRef();
  
  // function to open/close crop modal
  const toggleModalClassic = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setModalClassic(!modalClassic);
  };

  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9.]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "password":
        if (verifyLength(event.target.value, 1)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "number":
        if (verifyNumber(event.target.value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      default:
        break;
    }
    stateFunctions["set" + stateName](event.target.value);
  };

  React.useEffect(() => {
    async function fecthCategories() {
      try {
        setError(null);
        setLoading(true);
        setCategories([]);
        setitemCategories([]);
        const categoryList = await api.categories.list();

        if(categoryList.ok){
          setCategories(categoryList.body);
          categoryList.body.forEach(element => {
            if(element.name === 'Todo') setitemCategories([element.id]);
          });
        }else{
          if( categoryList.body.err && !categoryList.body.err.details ) setError(categoryList.body.err);
          if( categoryList.body.err && categoryList.body.err.details ) setError(categoryList.body.err.details[0].message);
          if( categoryList.body.hasOwnProperty("details") ) setError(categoryList.body.details);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Intenta de nuevo");
        setLoading(false);
      }
    }

    fecthCategories();

    window.addEventListener('unload', handleEndConcert);
    return () => {
      window.removeEventListener('unload', handleEndConcert);
      handleEndConcert();
    }
  }, []);

  const handleEndConcert = () => {
    props.emptyItemImages();
  }

  const onCheckCategory = (e, category) => {
    if(e.target.checked){
      setitemCategories([...itemCategories, category.id]);
    }else if(!e.target.checked){
      let new_categories = itemCategories.filter(element => category.id !== element);
      setitemCategories(new_categories);
    }
  }

  const setUploadImage = (file, base64) => {
    setImageToCrop({file, base64});
  }

  const removeImage = (image) => {
    // let images = itemImages.filter(item => file.name !== item.file.name);
    // setitemImages(images ? images : []);
    props.removeItemImage(image);
  }

  const defaultImage = (image) => {
    let oldDefaultImage = props.itemImages.find(item => item.isDefault === true);
    oldDefaultImage.isDefault = false;

    let newDefaultImage = props.itemImages.find(item => item.base64 === image.base64);
    if(newDefaultImage) {
      let index = props.itemImages.findIndex(item => item.base64 === image.base64)
      props.itemImages[index].isDefault = true;

      props.updateItemImage(props.itemImages[index]);
    }
  }

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = async (image, file) => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels
      )
      // console.log('donee', { croppedImage });
      let imageIndex = props.itemImages.findIndex(item => file.name === item.file.name);

      if(imageIndex  !== -1){
        let imageToReplace = props.itemImages[imageIndex];
        imageToReplace['base64'] = croppedImage;
        props.updateItemImage(imageToReplace);
        toggleModalClassic();
        setImageToCrop({});
      }else if(!props.itemImages.length){
        let image = {
          isDefault: true,
          file: file,
          base64: croppedImage
        }
        props.addItemImage(image);
        toggleModalClassic();
        setImageToCrop({});
      }else {
        let image = {
          isDefault: false,
          file: file,
          base64: croppedImage
        }
        props.addItemImage(image);
        toggleModalClassic();
        setImageToCrop({});
      }

    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  const createItem = async () => {
    if (skuItemState === "") {
      setskuItemState("has-danger");
    }if (nameItemState === ""){
      setnameItemState("has-danger");
    }if (priceItemState === ""){
      setpriceItemState("has-danger");
    }else if(skuItemState === "has-success" && nameItemState === "has-success" && priceItemState === "has-success") {
      try{
        setSaving(true);
        let createJSON = {
          sku: skuItem,
          name: nameItem,
          description: descriptionItem ? descriptionItem : null,
          price: priceItem,
          categories: itemCategories
        }
        let itemVariants = [];

        if(hasVariant){
          variants.forEach(element => {
            let optArray = [];
            element.options.forEach(option => {
              optArray.push(option.name);
            });
            let body = { [element.label] : optArray }
            itemVariants.push(body);
          });

          createJSON.variants = itemVariants;
        }

        const item = await api.items.create(createJSON);

        if(item.ok){
          mixpanelApi.items.itemCreated(item.body);
          if(!props.itemImages){
            setSaving(false);
            props.notify("tc", "success", 'Item '+item.body.name+' creado exitosamente.');
            props.history.push('/items');
          }else {
            createImages(item.body);
          }
        }else{
          if( item.body.err && item.body.err === "Item SKU already exists") props.notify("tc", "danger", `Error al guardar item: Código/SKU ya existe, debes de ingresar un Código/SKU único.`, 10);
          else if( item.body.err && !item.body.err.details ) props.notify("tc", "danger", `Error al guardar item: ${item.body.err}.`, 10);
          else if( item.body.err && item.body.err.details ) props.notify("tc", "danger", `Error al guardar item: ${item.body.err.details[0].message}.`, 10);
          else if( item.body.hasOwnProperty("details") ) props.notify("tc", "danger", `Error al guardar item: ${item.body.details}.`, 10);
          setSaving(false);
        }
      } catch (error) {
        console.log(error);
        setSaving(false);
        props.notify("tc", "danger", `Error al guardar item, intente de nuevo.`, 10);
      }
    }
  };

  const createImages = async (item) => {
    try{ 
      for(var i = 0; i < props.itemImages.length; i++) {
        const imageCreate = await api.items.createImage({
          item: item.id,
          image: props.itemImages[i].base64,
          isDefault: props.itemImages[i].isDefault
        });

        if(!imageCreate.ok) props.notify("tc", "danger", `Error al guardar imagen, intente de nuevo.`, 10);
      };

      setSaving(false);
      props.notify("tc", "success", 'Item '+item.name+' creado exitosamente.');
      props.history.push('/items');
    } catch (error) {
      console.log(error);
      setSaving(false);
      props.notify("tc", "danger", `Error al guardar imagen, intente de nuevo.`, 10);
      props.history.push('/items');
    }
  };

  if (loading === true) {
    return (
      <p>
        Cargando...
      </p>
    );
  }else if(error !== null){
    return (
      <ErrorMessage message={error} />
    )
  }else{
    return (
      <>
        <Row>
          <Col md="12" className="text-right pb-2">
              <Button
                onClick={() => props.history.push('/items')}
                color="neutral"
                className="btn-simple"
              >
                Atrás
              </Button>
              <Button
                disabled={skuItemState === 'has-danger' || nameItemState === 'has-danger' || priceItemState === 'has-danger' || saving}
                color="primary"
                onClick={createItem}
              >
                {saving ? 'Guardando' : 'Guardar'}
              </Button>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form id="RegisterValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Detalle</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup className={`has-label ${skuItemState}`}>
                    <label>Código/SKU *</label>
                    <Input
                      name="sku"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => change(e, "skuItem", "length", 1)}
                    />
                    {skuItemState === "has-danger" ? (
                      <label className="error">Código es requerido.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${nameItemState}`}>
                    <label>Nombre *</label>
                    <Input
                      name="name"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => change(e, "nameItem", "length", 1)}
                    />
                    {nameItemState === "has-danger" ? (
                      <label className="error">Nombre es requerido.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label`} >
                    <label>Descripción</label>
                    <Input
                      cols="80"
                      rows="4"
                      name="description"
                      type="textarea"
                      autoComplete="off"
                      onChange={(e) => stateFunctions["setdescriptionItem"](e.target.value)}
                    />
                  </FormGroup>
                  <div>
                    <label>Precio *</label>
                    <InputGroup
                      className={classnames(priceItemState, {
                        "input-group-focus": priceItemFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-coins" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="number"
                        type="text"
                        onChange={(e) => change(e, "priceItem", "number")}
                        onFocus={(e) => setpriceItemFocus(true)}
                        onBlur={(e) => setpriceItemFocus(false)}
                      />
                    </InputGroup>
                    {priceItemState === "has-danger" ? (
                      <label className="error">Precio es requerido y tipo numérico.</label>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Form>
          </Col>
          <Col md="6">
            <Card className="card-tasks"style={{height: '416px'}}>
              <CardHeader>
                <CardTitle tag="h4">Categorías</CardTitle>
              </CardHeader>
              <CardBody>
                <Col className="table-full-width table-responsive checkbox-radios" style={{maxHeight: '339px'}}>
                    {categories.map( item => {
                      return (
                        <FormGroup
                          key={item.id}
                          check
                          className="py-1"
                          disabled={item.name === 'Todo' ? true : false}
                        >
                          <Label
                            check
                            onChange={e => onCheckCategory(e, item)}
                          >
                            <Input
                              value={item.id}
                              name="optionCheckboxes"
                              type="checkbox"
                              disabled={item.name === 'Todo' ? true : false}
                              defaultChecked={item.name === 'Todo' ? true : false}
                            />
                            <span className="form-check-sign" />
                            {item.name}
                          </Label>
                        </FormGroup>
                      );
                    })}
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Variantes</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <div className="d-flex align-items-center">
                    <CustomInput
                      type="switch"
                      id={'switch-1'}
                      className="mt-n4"
                      onChange={(e) => {
                        sethasVariant(e.target.checked);
                        if(e.target.checked){
                          setCounter(0);
                          setVariants([{
                            id: counter,
                            label: "",
                            options: []
                          }]);
                        }
                      }}
                      defaultChecked={hasVariant}
                    />
                    <span>¿Este producto tiene variantes? Ejemplo: Tallas, Colores, etc...</span>
                  </div>
                </FormGroup>
                { hasVariant && (
                  <>
                    {variants.map(element => {
                      return (
                        <Row key={element.id}>
                          <Col md="3">
                            <FormGroup>
                              <label>Nombre</label>
                              <Input
                                type="text"
                                autoComplete="off"
                                defaultValue={element.label}
                                onChange={(e) => {
                                  element.label = e.target.value;
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="8">
                            <FormGroup>
                              <label>Agregar opciones</label>
                              <ReactTags
                                ref={reactTags}
                                tags={element.options}
                                onDelete={(i) => {
                                  element.options.splice(i,1);
                                }}
                                onAddition={(tagsinput) => {
                                  tagsinput.id = tagsinput.name;
                                  element.options.push(tagsinput);
                                }}
                                placeholderText="Presiona ENTER para agregarlo"
                                allowBackspace={false}
                                allowNew={true}
                                minQueryLength={1}
                              />
                            </FormGroup>
                          </Col>
                          <Button
                            onClick={() => {
                              setVariants(variants.filter(item => element.id !== item.id));
                            }}
                            className="btn-round btn-simple btn-icon mt-auto mb-2" color="danger">
                            <i className="tim-icons icon-trash-simple" />
                          </Button>
                        </Row>
                      );
                    })}
                    <Button
                      disabled={saving}
                      color="primary"
                      className="btn-simple"
                      onClick={() => {
                        let count = counter + 1;
                        setCounter(count);
                        setVariants([...variants, {
                          id: count,
                          label: "",
                          options: []
                        }])
                      }}
                    >
                      Agregar variante
                    </Button>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="3" sm="3">
                    <ImageItemUpload
                      addBtnColor="primary"
                      changeBtnColor="default"
                      setImage={setUploadImage}
                      setModalClassic={setModalClassic}
                    />
                  </Col>
                  {props.itemImages.map( item => {
                    return (
                      <Col key={item.file.name} className="fileinput text-center" md="2" sm="2">
                        <div className={"thumbnail"} >
                          <img
                            src={item.base64}
                            alt={item.file.name}
                            onClick={() => {
                              toggleModalClassic();
                              setUploadImage(item.file, item.base64);
                            }}
                          />
                        </div>
                        <Button
                          className="btn-sm"
                          color="primary"
                          onClick={() => {
                            toggleModalClassic();
                            setUploadImage(item.file, item.base64);
                          }}
                        >
                          <i className="fa fa-crop"></i>
                        </Button>
                        <Button
                          className="btn-sm"
                          color="success"
                          onClick={() => {
                            if(!item.isDefault){
                              defaultImage(item)
                            }
                          }}
                        >
                          { (item.isDefault === true) 
                            ? <i className={'fas fa-star'}></i>
                            : <i className={'far fa-star'}></i> 
                          }
                        </Button>
                        <Button
                          className="btn-sm"
                          color="danger"
                          onClick={() => removeImage(item)}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </Col>
                    );
                  })}
                  {/* Classic Modal */}
                  <Modal
                    modalClassName="modal-search"
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    style={{transform: "none"}}
                  >
                    <div className="modal-header justify-content-center">
                      <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassic}
                      >
                        <i className="tim-icons icon-simple-remove" />
                      </button>
                      <h6 className="title title-up">Ajusta la imagen dentro del lienzo</h6>
                    </div>
                    <ModalBody className="text-center">
                      <div style={{height: '350px'}}>
                        <Cropper
                          minZoom={0.2}
                          image={imageToCrop.base64}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          restrictPosition={false}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <Button
                        color="default"
                        type="button"
                        onClick={toggleModalClassic}
                      >
                        Atrás
                      </Button>
                      <Button
                        color="success"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => cropImage(imageToCrop.base64, imageToCrop.file)}
                      >
                        Cortar
                      </Button>
                    </div>
                  </Modal>
                  {/* End Classic Modal */}
                </Row>
              </CardBody>
            </Card>
            {/* end card */}
          </Col>
        </Row>
      </>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
