import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import PhoneInput from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es';
import 'react-phone-number-input/style.css';
import 'assets/css/signup.css';

import api from "../../api";

// Redux
import { connect } from 'react-redux';
import mixpanelApi from "../../mixPanel";

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

const Register = (props) => {
  const [state, setState] = React.useState({});
  const [fullName, setfullName] = React.useState();
  const [mobileNumber, setmobileNumber] = React.useState();
  const [password, setpassword] = React.useState();
  const [accountEmail, setaccountEmail] = React.useState();
  const [accountName, setaccountName] = React.useState();
  const [fullNameState, setfullNameState] = React.useState("");
  const [mobileNumberState, setmobileNumberState] = React.useState("");
  const [passwordState, setpasswordState] = React.useState("");
  const [accountNameState, setaccountNameState] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const stateFunctions = {
    setfullName: (value) => setfullName(value),
    setmobileNumber: (value) => setmobileNumber(value),
    setpassword: (value) => setpassword(value),
    setaccountName: (value) => setaccountName(value),
    setfullNameState: (value) => setfullNameState(value),
    setmobileNumberState: (value) => setmobileNumberState(value),
    setpasswordState: (value) => setpasswordState(value),
    setaccountNameState: (value) => setaccountNameState(value),
  }

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  });

  React.useEffect(() => {
    mixpanelApi.auth.viewSignupPage();
  }, []);
  
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
    return true;
    }
    return false;
  };

  // function that verifies if value contains only numbers
  const verifyNumber = (value) => {
    if(value !== undefined)
      value = value.split('+');

    var numberRex = new RegExp("^[0-9.]+$");
    if (numberRex.test(value !== undefined ? value[1] : value)) {
      return true;
    }
    return false;
  };

  const change = (value, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (verifyLength(value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      case "number":
        if (verifyNumber(value, stateNameEqualTo)) {
          stateFunctions["set" + stateName + "State"]("has-success");
        } else {
          stateFunctions["set" + stateName + "State"]("has-danger");
        }
        break;
      default:
        break;
    }

    stateFunctions["set" + stateName](value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);
    if (fullNameState === "") {
      setfullNameState("has-danger");
    }if (mobileNumberState === ""){
      setmobileNumberState("has-danger");
    }if (passwordState === ""){
      setpasswordState("has-danger");
    }if (accountNameState === ""){
      setaccountNameState("has-danger");
    }else if(fullNameState === "has-success" && mobileNumberState === "has-success" && passwordState === "has-success" && accountNameState === "has-success") {
      // setLoading(true);

      try{
        let countryCode = null;
        let countryName = null;
        let whatsapp = null;
        if(mobileNumber) whatsapp = mobileNumber.split('+');
        if(whatsapp[1].substring(0, 3) === '504'){
          countryCode = '60884f467b39de27e5a0c6e3';
          countryName = 'Honduras';
        }else if(whatsapp[1].substring(0, 3) === '503'){
          countryCode = '60884f5a7b39de27e5a0c6e4';
          countryName = 'El Salvador';
        }else if(whatsapp[1].substring(0, 3) === '502'){
          countryCode = '60884f617b39de27e5a0c6e5';
          countryName = 'Guatemala';
        }else{
          countryCode = '60ef78517f5e8225d4cffe69';
          countryName = 'Internacional';
        }

        let body = {
          fullName: fullName,
          mobileNumber: whatsapp[1],
          password: password,
          countryCode: countryCode,
          accountName: accountName
        };
        if(accountEmail) body.email = accountEmail;

        const response = await api.auth.signup(body);

        if(response.ok){
          localStorage.setItem('token', response.body.token);
          props.dispatch({
            type: 'SET_ACCOUNT',
            payload: { account: response.body.user.account }
          });

          props.dispatch({
            type: 'SET_USER',
            payload: { user: response.body.user }
          });
          response.body.countryName = countryName;
          mixpanelApi.auth.accountCreated(response.body);
          setLoading(false);
          props.history.push('/dashboard');
        }else {
          if(response.body.err === "Mobile number already exists" && !response.body.err.details){
            setError("Whatsapp ingresado ya esta registrado, intenta con un número diferente");
          }else if(response.body.err && !response.body.err.details){
            setError(response.body.err);
          }else {
            if(response.body.err.details[0].context.key === "fullName") setError("Nombre y Apellido es requerido");
            else if(response.body.err.details[0].context.key === "mobileNumber") setError("WhatsApp es requerido");
            else if(response.body.err.details[0].context.key === "password") setError("Contraseña es requerido");
            else if(response.body.err.details[0].context.key === "email") setError("Ingresa correctamente tu correo");
            else if(response.body.err.details[0].context.key === "accountName") setError("Nombre del negocio es requerido");
            else if(
              response.body.err.details[0].context.key !== "fullName" &&
              response.body.err.details[0].context.key !== "mobileNumber" &&
              response.body.err.details[0].context.key !== "password" &&
              response.body.err.details[0].context.key !== "email" &&
              response.body.err.details[0].context.key !== "accountName"
            ) setError(response.body.err.details[0].message);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  }

  return (
    <>
      <div className="content">
        <Container>
          <Row>
            <Col className="ml-auto" md="5">
              <div className="info-area info-horizontal" style={{marginBottom: '5rem'}}>
                <img
                  alt="expoceramica-signup-logo"
                  src={require("assets/img/expoceramica-logo.png").default}
                  width="180"
                />
              </div>
              <div className="info-area info-horizontal mt-5">
                <img
                  className="mb-2"
                  alt="expoceramica-signup-1"
                  src={require("assets/img/expoceramica-signup-1.png").default}
                  width="50"
                />
                <div className="description">
                  <h3 className="info-title text-white font-weight-bold" style={{fontSize: '1.25rem'}}>Asesórate con un experto</h3>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <img
                  className="mb-3"
                  alt="expoceramica-signup-2"
                  src={require("assets/img/expoceramica-signup-2.png").default}
                  width="50"
                />
                <div className="description">
                  <h3 className="info-title text-white font-weight-bold" style={{fontSize: '1.25rem'}}>Tu sitio m-Commerce en 5 min.</h3>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <img
                  className="mb-2"
                  alt="expoceramica-signup-3"
                  src={require("assets/img/expoceramica-signup-3.png").default}
                  width="50"
                />
                <div className="description">
                  <h3 className="info-title text-white font-weight-bold" style={{fontSize: '1.25rem'}}>Crece tu negocio con internet y WhatsApp</h3>
                </div>
              </div>
            </Col>
            <Col className="mr-auto" md="7">
              <Form className="form" onSubmit={handleSubmit}>
                <Card className="card-register card-white px-5" style={{boxShadow: '0 1px 10px 0px rgba(0, 0, 0, 0.3)'}}>
                  <CardHeader>
                    <p className="mt-4 mb-5" style={{fontSize: '1rem'}}>¿Ya tienes cuenta? {" "}
                      <Link to="/auth/login">
                        Inicia sesión
                      </Link>
                    </p>
                    <CardTitle tag="h1">Inicia con tu cuenta gratis</CardTitle>
                    <p>
                      Sin comisiones. Sin contratos.<br/>
                      No necesitas tarjeta de crédito
                    </p>
                  </CardHeader>
                  <CardBody>
                    <FormGroup
                      className={classnames({
                        "input-group-focus": state.nameFocus,
                      })}
                    >
                      <p className="font-weight-bold">Nombre y Apellido*</p>
                      <Input
                        type="text"
                        onFocus={(e) => setState({ ...state, nameFocus: true })}
                        onBlur={(e) => setState({ ...state, nameFocus: false })}
                        onChange={(e) => change(e.target.value, "fullName", "length", 1)}
                      />
                      {fullNameState === "has-danger" ? (
                        <label className="error text-danger">Nombre y Apellido es requerido.</label>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        "input-group-focus": state.whatsappFocus,
                      })}
                    >
                      <p className="font-weight-bold">WhatsApp*</p>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="HN"
                        value={mobileNumber}
                        onChange={(phone) => change(phone, "mobileNumber", "number", 1)}
                        labels={es}
                        countryOptionsOrder={['HN', 'GT', 'SV']}
                      />
                      {mobileNumberState === "has-danger" ? (
                        <label className="error text-danger">Whatsapp es requerido.</label>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        "input-group-focus": state.passFocus,
                      })}
                    >
                      <p className="font-weight-bold">Contraseña*</p>
                      <Input
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={(e) => change(e.target.value, "password", "length", 1)}
                      />
                      {passwordState === "has-danger" ? (
                        <label className="error text-danger">Contraseña es requerido.</label>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        "input-group-focus": state.emailFocus,
                      })}
                    >
                      <p className="font-weight-bold">Email</p>
                      <Input
                        type="text"
                        onFocus={(e) => setState({ ...state, emailFocus: true })}
                        onBlur={(e) => setState({ ...state, emailFocus: false })}
                        onChange={(e) => setaccountEmail(e.target.value ? e.target.value : null)}
                      />
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        "input-group-focus": state.accountFocus,
                      })}
                    >
                      <p className="font-weight-bold">Nombre del negocio*</p>
                      <Input
                        type="text"
                        onFocus={(e) => setState({ ...state, accountFocus: true })}
                        onBlur={(e) => setState({ ...state, accountFocus: false })}
                        onChange={(e) => change(e.target.value, "accountName", "length", 1)}
                      />
                      {accountNameState === "has-danger" ? (
                        <label className="error text-danger">Nombre del negocio es requerido.</label>
                      ) : null}
                    </FormGroup>
                    {error &&
                      <p className="text-danger text-center">{error}</p>
                    }
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={loading}
                      type="submit"
                      color="primary"
                      size="lg"
                    >
                      { loading ? 'Creando Cuenta...' : 'Crear Cuenta' }
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Register);
